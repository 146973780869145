@charset "UTF-8"; 

/**************************************************
   Layout
**************************************************/
#root{min-width:360px;width:100%;height:100%;box-sizing: border-box;
   #container{position: relative;width: 100%;height: 100%;padding: 52px 0 0 0;overflow: auto;
      .mainWrap{position:relative;width:100%;}
      .brandWrap{position:relative;width:100%;}
      .serviceWrap{position:relative;width:100%;}
      .introductionWrap{position:relative;width:100%;}
      .mediaWrap{position:relative;width:100%;}
      .virtualExWrap{position:relative;width:100%;}
      .ceoWrap{position:relative;width:100%;}
      .termsWrap{position:relative;width:100%;}
   }
}

/**************************************************
   Header
**************************************************/
.headerWrap{position:fixed;top:0;left:0;min-width:360px;width:100%;padding:0 0;box-sizing:border-box;z-index:99;transition:top 0.1s ease-in-out;
   .headerTop{min-height:52px;height:52px;
      h1{width:128px;height:26px;margin:12px 0 0 16px;
         img{width:100%;}
      }
      .btnBox{height:40px;margin:6px 8px 0 0;}
   }
   &.hide{top:-52px;}
   &.main{background:#1a1a1b;min-height:52px; height: 52px;
      .headerTop{
         .btnBox{
            .gnbBtn{width:40px;height:40px;background: url('../../images/kor/ico/btn-menu-w@2x.png') no-repeat center;background-size:40px;}
            .closeBtn{display:none;}
         }
      }
      .subLnbTopBox{display: none;}
   }
   &.sub{background:#ffffff;
      .headerTop{background-color: #ffffff;
         .btnBox{
            .gnbBtn{width:40px;height:40px;background: url('../../images/kor/ico/btn-menu@2x.png') no-repeat center;background-size:40px;}
            .closeBtn{display:none;}
         }
      }
      //서브 공통 LNB
      .subLnbTopBox{z-index: -1; position: absolute; top: 0; left: 0; right: 0; height: 52px;font-size: 14px; font-family: 'NSKB'; letter-spacing: -0.35px; line-height: 52px; padding: 0 20px; border: solid #ececec; border-width: 1px 0; background-color: #ffffff; box-sizing: border-box;
         .subLnbMenuBox{z-index: 10; position: absolute; top: 51px; left: 0; right: 0; padding: 0 20px; background-color: #ffffff; color: #333333; box-sizing: border-box;
            ul{
               li{height: 52px; border-bottom: 1px solid #f0f0f0;
                  &:last-child{border-bottom: none;}
                  &.on{color: #4d7cfe;}
               }
            }
         }     
         .arr{position: absolute; right: 20px; top: 14px; width: 24px; height: 24px; background: url('../../images/kor/ico/ico-arr-down@2x.png') no-repeat center; background-size: 24px;
            &.up{transform: rotate(0deg); transition:all 0.3s;}
            &.down{transform: rotate(180deg); transition:all 0.3s;}
         }
         &.sticky{z-index: 99; position: absolute; top: 51px;}
         &.on{background-color: #f3f4f5;}
      }
   }
   &.main.gnb,
   &.sub.gnb{background-color:#ffffff;height:100%;
      .headerTop{
         .btnBox{
            .gnbBtn{display:none;}
            .closeBtn{display:block;width:40px;height:40px;background: url('../../images/kor/ico/btn-close@2x.png') no-repeat center;background-size:40px;}
         }
      }
   }
   .gnbBox{overflow-y:auto;overflow-x: hidden;border-top:1px solid #ececec;
      li{position:relative;border-bottom:1px solid #ececec;
         &::after{content:'';position:absolute;top:18px;right:16px;width:24px;height:24px;background:url('../../images/kor/ico/ico-arr-down@2x.png') no-repeat center;background-size:24px;pointer-events:none;}
         .gnbText{height:60px;padding:0 16px 0 20px;font-size:18px;font-family: 'NSKB';line-height:60px;letter-spacing:-0.45px;color:#000000;}
         .subBox{display:none;padding:0 0 10px 0;
            .subText{height:42px;padding:0 0 0 20px;font-size:16px;line-height:42px;font-family: 'NSKR';letter-spacing:-0.4px;color:#333333;
               &.on{color:#4d7cfe}
               &:active{background-color:rgb(77 124 254 / 10%);}
            }
         }
         &.on{
            &::after{content:'';position:absolute;top:18;right:16px;width:24px;height:24px;background:url('../../images/kor/ico/ico-arr-up@2x.png') no-repeat center;background-size:24px;pointer-events:none;}
            .subBox{display:block;}
         }
      }
   }
   .shopComboBox{position: relative;margin:30px 20px 0 20px;
      &::after{content: ""; display: block; clear: both; height: 0; visibility: hidden;}
      .inbox{position: relative; width: 100%; height: 44px; padding: 10px 0 0 16px; font-size: 16px; color: #000000; letter-spacing: -0.38px; border: 1px solid #dbdbdb; box-sizing: border-box;
         .arr{position: absolute; top: 12px; right: 13px; width: 18px; height: 18px; background: url("../../images/kor/ico/ico-arrow-down@2x.png") no-repeat center; background-size: 18px;
            &.up{transform: rotate(0deg); transition: all 0.3s;}
            &.down{transform: rotate(180deg); transition: all 0.3s;}
         }
         &.act{border: 1px solid #666666; transition: all 0.3s;}
      }
      .list{width: 100%; border: 1px solid #dddddd; background: #ffffff; box-sizing: border-box;margin-top:-1px;
         ul{
            li{padding: 0 16px; font-size: 15px; line-height: 44px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;
               &::after{display:none;}
               &.on{color: #4d7cfe; background-color: rgba(77, 124, 254, 0.1);}
               &:active{color: #4d7cfe; background-color: rgba(77, 124, 254, 0.1);}
            }
         }
         &.act{border-top: 1px solid #666666; transition: all 0.3s;}
      }
   }
   .langBox{min-height: 60px;margin: 10px 0 0 0;justify-content: center;box-sizing: border-box;
      .language{align-items: center; font-size: 15px; letter-spacing: -0.38px; color: #8b8b8f;
         &::after{content: ''; width: 1px; height: 14px; margin: 0 20px; background-color: #ececec;}
         &:last-child{
            &::after{display: none;}
         }
         &.on{color: #607fff; font-family: 'NSKB'; text-decoration: underline;}
      }
   }
   .scrollBar{position: absolute;bottom:-4px;left:0;right:0;height:4px;
      .bar{height:4px;background-color:#4d7cfe;}
   }
}

/**************************************************
   Footer
**************************************************/
.footerWrap{position: relative; padding: 30px 20px 50px; min-height: 226px; color: #7b7b7b; box-sizing: border-box;
   &.main{background-color:#1a1a1b;}
   // [개발검수1219] 다국어 수정 
   &.sub{background-color:#f9fafb;
      .footerTop{
         .title{
            color: #7b7b7b;
            &::after{opacity: 1; background-color: #dddddd;}
         }
      }
      .comboBox{
         .inbox{border: 1px solid #dbdbdb;}
         .list{position: absolute; bottom: 64px; right: 0px; left: 0; border: 1px solid #dbdbdb; color: rgba(75, 78, 83, 0.8); background-color: #f9fafb; box-sizing: border-box;}
      }
      .num{
         em{
            &::after{content: ''; display: inline-block; width: 1px; height: 10px; margin: 0 5px; opacity: 0.4; background-color: #bababa;}
         }
      }
   }
   // [개발검수1219] 다국어 수정 
   .footerTop{font-size: 13px; letter-spacing: -0.33px;
      .title{
         color: #7b7b7b;
         align-items: center;
         &:last-child::after{display: none;}
         &.tb{font-family: 'NSKB';}
         &::after{content: ''; display: inline-block; width: 1px; height: 13px; margin: 0 10px 1px; opacity: 0.4; background-color: #bababa;}
      }
   }
   .comboBox{position: relative; padding: 16px 0 25px;
      &::after{content: ""; display: block; clear: both; height: 0; visibility: hidden;}
      .inbox{height: 40px; padding: 0 40px 0 17px; font-size: 13px; line-height: 37px; letter-spacing: -0.33px; border: 1px solid rgba(219, 219, 219, 0.2); border-radius: 4px; box-sizing: border-box;}
      .arr{position: absolute; top: 23px; right: 10px; width: 24px; height: 24px; background: url('../../images/kor/ico/ico-arr-up-footer-w@2x.png') no-repeat center; background-size: 24px;
         &.up{transform: rotate(0deg); transition:all 0.3s;}
         &.down{transform: rotate(180deg); transition:all 0.3s;}
      }
      .list{z-index: 99; position: absolute; bottom: 64px; right: 0px; left: 0; border: 1px solid rgba(219, 219, 219, 0.2); color: rgba(219, 219, 219, 0.8); background-color: rgba(26, 26, 27, 0.8); box-sizing: border-box;
         ul{
            li{padding: 10px 15px 5px; font-size: 13px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;
               &:last-child{padding-bottom: 10px;}
            }
         }
      }
   }
   .footerBtm{
      .title{width: 160px; font-size: 13px;font-family: 'NSKB';letter-spacing: -0.33px; background: url('../../images/kor/ico/ico-arr-down-footer-s-w@2x.png') no-repeat right; background-size: 18px;
         &.arrUp{background: url('../../images/kor/ico/ico-arr-up-footer-s-w@2x.png') no-repeat right; background-size: 18px;}
      }
      .btmIn01{margin-top: 20px; font-size: 12px; letter-spacing: -0.6px;
         table{
            tr{text-align: left; vertical-align: text-top;
               th{width: 84px; line-height: 2.08;}
               td{padding-left: 10px;}
            }
         }
         .num{margin-top: 15px;
            em{vertical-align: middle; line-height: 1.75;
               &::after{content: ''; display: inline-block; width: 1px; height: 10px; margin: 0 5px; opacity: 0.4; background-color: #bababa;}
               &:last-child::after{display: none;}
            }
         }
      }
      .copyright{margin-top: 28px;}
   }
   .footerFnBox{position: fixed;bottom: -20px;right: 20px;z-index: 10;
      .topScroll{width: 40px; height: 40px; background:#484848 url('../../images/kor/ico/ico-tothetop@2x.png') no-repeat center 0px; background-size: 42px;}
   }
   .floatingBannerBox{position: fixed;bottom: 0px;right: -4px;width:158px;height:92px;background:url('../../images/kor/temp/img-banner-purchase-mobile@2x.png') no-repeat center;background-size:contain;cursor: pointer;z-index: 10;transition: 0.3s;}
}

/******************************************************
    공통 팝업스타일
*******************************************************/
// [개발검수1107] 다국어 수정 
.popup-wrap{position: fixed;top: 50%;transform:translateY(-50%);left: 20px;right: 20px;border-radius:4px;box-sizing: border-box;background-color: #ffffff;z-index: 1000;overflow:hidden;
   .popupHeader{height:53px;padding:0 16px;border-bottom:1px solid #e5e5e5;box-sizing: border-box;
      .popTitle{font-size:17px;line-height:53px;font-weight: 'NSKB'; text-align:left;letter-spacing:-0.43px;color:#000000;}
      .popClose{width:24px;height:24px;margin:15px 0;background:url('../../images/kor/ico/ic-close-bl@2x.png') no-repeat center;background-size:24px;}
   }
   .popupContainer{overflow: auto;}
}
@media (max-width: 319px) { .popup-wrap{left: -20px;right: -20px;transform:scale(0.78);} } // @media
@media (min-width: 320px) and (max-width: 359px) { .popup-wrap{left: 0px;right: 0px;transform:scale(0.9);} } // @media

// 딤처리 공통
.dim{position: fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.5);z-index:999;touch-action: none;}

/**************************************************
   서브 공통 레이아웃
**************************************************/
//상단 헤더
.subMainTop{left: 50%;min-width: 360px; height:296px; position: relative; transform: translateX(-50%); z-index: 10;
   &.brand{background: url("../../images/kor/bg/img_kv_brand@2x.png") center no-repeat; background-size: cover;}
   &.service{background: url("../../images/kor/bg/img_kv_service@2x.png") center no-repeat; background-size: cover;}
   &.introduction{background: url("../../images/kor/bg/img_kv_info@2x.png") center no-repeat; background-size: cover;}
   &.media{background: url("../../images/kor/bg/img_kv_media@2x.png") center no-repeat; background-size: cover;}
   &.virtualEx{background: url("../../images/kor/bg/img_kv_metaverse@2x.png") center no-repeat; background-size: cover;}
   &.ceo{background: url("../../images/kor/bg/img_kv_insight@2x.png") center no-repeat; background-size: cover;}
   &.terms{background: url("../../images/kor/bg/img_kv_terms@2x.png") center no-repeat; background-size: cover;}

   dl{padding:70px 20px 0 20px;
      dt{font-size: 23px; font-family:'NSKB';padding-bottom:12px;color:#fff;}
      dd{font-size: 15px; line-height: 1.47;color:rgba(255, 255, 255, 0.8);}
   }

   //서브 2depth
   .subLnbBox{position: relative; height: 48px; color:#fff; font-size: 14px; margin-top:44px;
      .subLnbMenu{height: 48px; margin:0px 20px; padding:0 20px; line-height: 48px; border-radius: 5px; box-shadow: 0 2px 8px 0 rgba(#000, 0.12); border: solid 1px rgba(#fff, 0.18); background-color: rgba(#fff, 0.14); box-sizing: border-box;}
      .subLnbMenuBox{
         position: absolute; top: 48px; left: 20px; right: 20px; padding:13px 20px; border-radius: 5px; box-shadow: 0 2px 8px 0 rgba(#000, 0.12); border: solid 1px rgba(#fff, 0.3); background-color: rgba(26, 26, 27, 0.8); color: rgba(219, 219, 219, 0.8); cursor: pointer; line-height:30px; color:#fff; box-sizing: border-box;
         a{
            color:#fff;
         }
      }     
      .arr{position: absolute; right: 30px; top:13px; width: 24px; height: 24px; background: url('../../images/kor/ico/ico_arr_down_w@2x.png') no-repeat center; background-size: 24px;
         &.up{transform: rotate(0deg); transition:all 0.3s;}
         &.down{transform: rotate(180deg); transition:all 0.3s;}
      }
   }
}

//서브 컨텐츠 탭
.menuBox{height: 48px; z-index: 1; position: sticky; top: 50px; transition: top 0.1s ease-in-out; border-bottom:1px solid #ececec; background-color: #ffffff; box-sizing:border-box;
   &.hide{top: -1px;}
   .detailsMenuBox{overflow-y: hidden; overflow-x: auto; -webkit-overflow-scrolling : touch;
      &::-webkit-scrollbar{display:none;}
      ul{
         li{position: relative; min-width: auto; margin:0 10px;border-bottom: 2px solid transparent; white-space: nowrap; text-align:center; box-sizing: border-box;
            // [개발검수1107] 다국어 수정 
            &:first-child{margin-left: 0px;
               p{margin-left: 24px;}
            }
            &:last-child{margin-right: 0px;
               p{margin-right: 24px;}
            }
            p{display: inline-block; min-width: 50px; height: 48px; padding:0 4px; line-height: 48px; font-size: 14px; font-family: 'NSKB'; letter-spacing: -0.35; color:#8b8b8f; box-sizing: border-box;
               &.on{color:#607fff; border-bottom:2px solid #607fff; transition: all 0.2s;}
            }   
         }
      }
   }
}

/**************************************************
   컨텐츠 (공통)
**************************************************/
.cover{z-index: 11;position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.6);}

.contentsBox{min-height:200px;
   .inner{position: relative; padding:60px 0 0 0;
      .line{display: block; width: 40px; height: 3px; background-color: #607fff; margin:0 auto 22px;}
      .box01{width: 100%; height: 295px; margin: 20px auto 0; padding: 16px 28px 32px; text-align: center; border-radius: 6px; border-top: 3px solid #607fff; background-color: #ffffff; box-shadow: 0 3px 16px 0 rgba(87, 104, 175, 0.08); box-sizing: border-box;}
      .box02{width: 100%; height: 360px; margin: 20px auto 0; border-radius: 6px; border: 1px solid #b5c8ff; box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.04);}
      // [개발검수1107] 다국어 수정 
      .box03{width: 100%; margin: 10px auto 0; padding: 0 24px 30px; border-radius: 6px; border: 1px solid #e1e8f5; background-color: #ffffff; box-shadow: 0 6px 30px 0 rgba(17, 76, 170, 0.12); box-sizing: border-box;}
   }   
   .engText{font-size: 14px; font-family: 'NSKB'; letter-spacing: -0.33px; text-align: center; color: #4d7cfe;}
   .bText{font-size: 25px; font-family: 'NSKB'; line-height:34px; letter-spacing: -0.63px; text-align: center; color:#000;
      em{color: #4d7cfe;}
   }
   .sText{font-size: 16px; line-height:24px; letter-spacing: -0.4px; text-align: center; color:#000; padding:15px 0 0 0;
      strong{font-family: 'NSKB';}
   }
   .btn{width: 100%; height: 52px; margin: 40px auto 0; border-radius: 4px; border: none; font-size: 15px; font-family: 'NSKB'; letter-spacing: -0.38px; color: #ffffff; box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16); background-image: linear-gradient(98deg, #607fff -11%, #786eff);
      &:active{background-image: linear-gradient(82deg, #5472ef 4%, #5a50e0 95%);}
      &:disabled{color: #8b8b8f; background-color: #e5e5e5;}
   }
}

// [개발검수0908] css수정
// [개발검수0922] input 폰트 사이즈 변경 (selectBox는 input이 아니지만 통일을 위해 변경함)
.selectBox{position: relative; width: 100%;min-width:105px;
   &::after{content: ""; display: block; clear: both; height: 0; visibility: hidden;}
   .inbox{position: relative; width: 100%; height: 44px; padding: 10px 0 0 16px; font-size: 16px; color: #000000; letter-spacing: -0.38px; border: 1px solid #dbdbdb; box-sizing: border-box;
      .arr{position: absolute; top: 12px; right: 13px; width: 18px; height: 18px; background: url("../../images/kor/ico/ico-arrow-down@2x.png") no-repeat center; background-size: 18px;
         &.up{transform: rotate(0deg); transition: all 0.3s;}
         &.down{transform: rotate(180deg); transition: all 0.3s;}
      }
      &.act{border: 1px solid #666666; transition: all 0.3s;}
   }
   .list{z-index: 1; position: absolute !important; top: 43px; left: 0px; width: 100%; border: 1px solid #dddddd; background: #ffffff; box-sizing: border-box;
      ul{
         li{padding: 0 16px; font-size: 15px; line-height: 44px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;
            &.on{color: #4d7cfe; background-color: rgba(77, 124, 254, 0.1);}
            &:active{color: #4d7cfe; background-color: rgba(77, 124, 254, 0.1);}
         }
      }
      &.act{border-top: 1px solid #666666; transition: all 0.3s;}
   }
   &.disa{background-color: #f8f9fa;
      .inbox{color: #ababac;}
      .arr{background: url("../../images/kor/ico/ico-arrow-down-disabled@2x.png") no-repeat center; background-size: 18px;}
   }
}

//검색 박스
// [개발검수0922] input 폰트 사이즈 변경
.searchBox{width: 300px; height: 44px; padding: 10px 16px; border: 1px solid #dbdbdb; box-sizing: border-box; background: url('../../images/kor/sub/media/ic-search-m-normal@2x.png') no-repeat right 16px center; background-size: 18px;
   input{width: 90%; height: 100%; outline: none; border: none; font-size: 16px;
      &::placeholder{font-size: 16px; letter-spacing: -0.4px; color: #999999;}
   }
   &.searchNoticeBox{
      background: none;
      display:flex;
      justify-content: space-between;
      input{
         flex:1;
      }
      button.mobileSearchBt{
         padding:0px;
         border:none;
         background: none;
         
         img{
            width:20px;
            
         }
         
      }
   }
}

//paging
.paging{justify-content: center; margin-top: 40px; align-items: center;
   .first{width: 18px; height: 18px; margin-right: 8px; background: url(../../images/kor/sub/media/ico-first-normal@2x.png) no-repeat center center; background-size: 18px; cursor: pointer;}
   .prev{width: 18px; height: 18px; background: url(../../images/kor/sub/media/ico-previous-normal@2x.png) no-repeat center center; background-size: 18px; cursor: pointer;}
   .next{width: 18px; height: 18px; margin-right: 8px; background: url(../../images/kor/sub/media/ico-next-normal@2x.png) no-repeat center center; background-size: 18px; cursor: pointer;}
   .last{width: 18px; height: 18px; background: url(../../images/kor/sub/media/ico-last-normal@2x.png) no-repeat center center; background-size: 18px; cursor: pointer;}
   .numBox{margin-left: 17px; margin-right: 17px;
      .num{width: 40px; height: 40px; text-align: center; margin-right: 2px; font-size: 16px; line-height: 40px; border-radius: 100px; cursor: pointer;
         &.on, &:hover{border: 1px solid #607fff; font-family: 'NSKB'; letter-spacing: -0.4px; color: #4d7cfe;}
         &:last-child{margin-right: 0;}
      }
   }
   .disable{
      opacity: 0.5;
   }
}

//버튼
.btn_downLoad{display: flex; justify-content: center;
   .btnBox{width: 206px; height: 48px; line-height: 48px; opacity: 0.9; border-radius: 100px; box-shadow: 0 3px 10px 0 rgba(123, 123, 123, 0.2);  border: solid 1px #4d7cfe;text-align:center; color: #4d7cfe;font-size: 15px; font-weight: 500;
      span{padding-right:20px; background:url('../../images/kor/ico/ico_download@2x.png') no-repeat center right; background-size: 18px 18px;}
   }
}
.btn_arrow{display: block; padding: 13px 46px 13px 30px; margin: 0 auto; font-size: 15px; font-weight: 500; letter-spacing: -0.38px; color: #ffffff; border: none; outline: none; border-radius: 50px; background-image: url('../../images/kor/sub/ceo/ico-arrow-right-w@2x.png'), linear-gradient(78deg, #607fff 4%, #786eff 95%); background-position: right 30px center, center center; background-repeat: no-repeat; background-size: 14px, cover;
   &:active{background-image: url('../../images/kor/sub/ceo/ico-arrow-right-w@2x.png'), linear-gradient(82deg, #5472ef 4%, #5a50e0 95%); background-position: right 30px center, center center; background-repeat: no-repeat; background-size: 14px, cover;}
   &:disabled{color: #8b8b8f; background: #e5e5e5;}
   &.bor{color: #222222; border: 1px solid #222222; background: transparent url('../../images/kor/sub/ceo/ico-arrow-right@2x.png') no-repeat right 30px center; background-size: 14px;}
}

//테이블 타이틀
.tBox{align-items: baseline; width: 100%; margin: 60px auto 0; padding-bottom: 20px; border-bottom: 1px solid #000000; box-sizing: border-box;
   &.nonBd{border-bottom: none;}
   .boxTitle{font-size: 20px; font-family: 'NSKB'; letter-spacing: -0.5px; color: #222222;}
   .com{font-size: 14px; letter-spacing: -0.35px; color: #121212;
      em{color: #607fff;}
   }
}

//input
// [개발검수1107] input 폰트 사이즈 변경
.inputBox{
   .inputText{width: 100%; height: 44px; padding: 10px 16px; border: 1px solid #dbdbdb; border-radius: 0; outline: none; font-size: 15px; letter-spacing: -0.35px; box-sizing: border-box;
      &:focus{border: 1px solid #666666; transition: all 0.3s;}
      &.disa{background-color: #f8f9fa;}
      // [개발검수0921] 필수항목 미입력시 하이라이트 스타일
      &.select{border: 1px solid #607fff;}
   }
   ::-webkit-input-placeholder{color:#ababac;}
}
// [개발검수0922] input 폰트 사이즈 변경
.RadioBox{margin: 9px 0;
   input[type="radio"]{margin-top: 1px; margin-left: 1px; vertical-align: middle; visibility: hidden;}
   input[type="radio"] + label {margin: 0 50px 0 -17px; font-size: 16px; letter-spacing: -0.38px; display: inline-block; min-height: 11px; height: auto; background: url("../../images/kor/ico/btn-radio-off@2x.png") no-repeat left center; background-size: 26px; padding: 3px 0 2px 30px; overflow: hidden; vertical-align: middle; text-indent: 4px;}
   input[type="radio"] + label:last-child{margin-right: 0px;}
   input[type="radio"]:checked + label{background: url("../../images/kor/ico/btn-radio-on@2x.png") no-repeat left center; background-size: 26px; }
   input[type="radio"]:disabled + label{background: url("../../images/kor/ico/btn-radio-disabled@2x.png") no-repeat left center; background-size: 26px; }
}
// [개발검수0922] input 폰트 사이즈 변경
.checkBox{margin: 9px 0 0 -5px; font-size: 16px; line-height: 26px; letter-spacing: -0.38px;
   .check01{display: inline-block; padding-left: 40px; margin: 0 20px 18px 0; background: url("../../images/kor/ico/btn-check-off@2x.png") no-repeat left; background-size: 26px;
      &.etc{margin-bottom: 15px;}
      &.IconOff{padding-left: 40px; margin-right: 20px; background: url("../../images/kor/ico/btn-check-on@2x.png") no-repeat left; background-size: 26px;}
   }
}
// [개발검수0922] input 폰트 사이즈 변경
.textarea{width: 100%; height: 286px; padding: 11px 14px; font-size: 16px; border: 1px solid #dbdbdb; border-radius: 0; resize: none; outline: none; box-sizing: border-box;
   &:focus{border: 1px solid #666666; transition: all 0.3s;}
   &:focus::-webkit-input-placeholder{color: transparent;}
   &::-webkit-input-placeholder{color:#ababac;}
   &::-webkit-scrollbar{width: 15px;}
   &::-webkit-scrollbar-thumb{background-color:#dbdbdb; border-radius: 15px; background-clip: padding-box; border: 5px solid transparent;}
   &::-webkit-scrollbar-track{background-color:#ffffff;}
}
.agreeBox{
   .agreeText{overflow: auto; overflow-x: hidden; width: 100%; height: 286px; font-size: 15px; padding: 11px 14px; line-height: 1.53; letter-spacing: -0.38px; background-color:#fafbfc; border: 1px solid #dbdbdb; color:#4a4a4a;
      .tit{font-size: 14px; font-family: 'NSKB'; letter-spacing: -0.35px;}
      .text{font-size: 14px; letter-spacing: -0.35px; line-height: 1.57; color: #727272;}
   }
   ::-webkit-scrollbar{width: 15px;}
   ::-webkit-scrollbar-thumb{background-color:#dbdbdb; border-radius: 15px; background-clip: padding-box; border: 5px solid transparent;}
   ::-webkit-scrollbar-track{background-color:#fafbfc;}
}
.agreeBtnBox{margin: 16px 0 0 0; line-height: 24px; font-size: 15px; letter-spacing: -0.38px;
   .agreeCheck{display: inline-block; padding-left: 34px; background: url("../../images/kor/ico/btn-check-off@2x.png") no-repeat top left; background-size: 26px;
      &.agreeIconOff{background: url("../../images/kor/ico/btn-check-on@2x.png") no-repeat top left; background-size: 26px;}
      .colorBlue{color: #607fff;}
      .colorGray{color: #8b8b8f;}
   }
}

//modal
.modal{z-index: 100; position: fixed;top: 0;width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.7);
   .closeBox{position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 320px; height: 274px;
      .closeIcon{z-index: 10; position: absolute; top: 0; right: -5px; width: 40px; height: 40px;background: url('../../images/kor/sub/media/btn-close-w@2x.png') no-repeat center; background-size: cover;}
      .count{position: absolute; bottom: 10px; left: 50%; transform: translateX(-50%); color: #e2e2e2; font-size: 14px; letter-spacing: -0.35px;}
   }
   .closePrintBox{position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 319px; height: 537px;
      &.printBoxSize{width: 320px; height: 550px;
         .closeIcon{right: -5px;}
         .count{bottom: 12px;}
      }
      .closeIcon{z-index: 10; position: absolute; top: 0; right: -5px; width: 40px; height: 40px; background: url('../../images/kor/sub/media/btn-close-w@2x.png') no-repeat center;background-size: cover;cursor: pointer;}
      .count{position: absolute; bottom: 7px; left: 50%; transform: translateX(-50%); color: #e2e2e2; font-size: 14px; letter-spacing: -0.35px;}
   }
}

//scroll
.scrollList{position:relative; margin: 40px auto 0;
   &.mediaScroll{
      .ScrollbarsCustom-Wrapper, .ScrollbarsCustom-Scroller{position: relative !important;}
      .ScrollbarsCustom-Content{padding: 0 !important;}
      .ScrollbarsCustom-TrackY{display: none !important;}
      .ScrollbarsCustom-TrackX{position: relative !important; left: 20px !important; width: calc(100% - 40px) !important; height: 1px !important; margin-top: 40px !important; background: #e5e5e5 !important; overflow: visible !important;
         .ScrollbarsCustom-ThumbX{position: absolute !important; top: -0.5px !important; height: 2px !important; border-radius: 2px !important; background: #607fff !important;}
      }
   }
   .videoAllBox{position: relative; min-width: 304px; margin-right: 16px;
      &:last-of-type{margin-right: 0;}
      &:first-of-type{margin-left: 20px;}
      img{width: 304px; height: 170px;}
      // [개발검수1102] 이미지 둥근 모서리
      .video01, .video02, .video03{border-radius: 6px;}
      .play{position: absolute; top: 53px; left: 122px; width: 70px; height: 70px; background: url('../../images/kor/sub/media/btn-play@2x.png') no-repeat center center; background-size: 70px;}
   }
   .printAllBox{width: 220px; margin-right: 16px;
      &:last-of-type{margin-right: 0px;}
      &:first-of-type{margin-left: 20px;}
      // [개발검수1102] 이미지 둥근 모서리
      img{width:100%; height: 303px; border-radius: 6px; border: solid 1px #e3e3e3; box-sizing: border-box;}
      .print01, .print02{border-radius: 6px;}
      .print03{width: 220px; height: 303px; border-radius: 6px; border: solid 1px #e3e3e3; box-sizing: border-box; background-color: #f9fafb;
         .logo{margin: 140px auto 0; width: 128px; height: 26px; background: url('../../images/kor/temp/lg-amaranth-10@2x.png') no-repeat center; background-size: 128px 26px;}
      }
   }
   .textBox{margin-top: 14px; max-width: 300px; 
      .text{font-size: 16px; font-family: 'NSKB'; letter-spacing: -0.4px;}
      .text2{margin-top: 4px; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; font-size: 15px; font-weight: 500; letter-spacing: -0.38px; line-height: 1.53; color: #8b8b8f;}
      .day{margin-top: 10px; font-size: 14px; letter-spacing: -0.35px; color: #ababac;}
   }
}

/**************************************************
   Brand (브랜드)
**************************************************/
#BrandSub01{position:relative;
   .brand01{
      .pt120{padding-top:120px;}
      .title{font-size: 25px;line-height: 34px;letter-spacing: -0.63px; font-family: 'NSKB'; padding:60px 20px 40px 20px;}      
      .cTitle{font-size: 25px; font-family: 'NSKB';line-height: 34px;letter-spacing: -0.63px;color: #818181; padding:0 0 18px 0;
         em{color:#000;}
      }
      .cText{font-size: 16px;letter-spacing: -0.4px;line-height: 24px;color: #86868b;font-family: 'NSKB';padding:0 0 40px 0;}
      .bTitle{font-size: 25px;color: #121212;letter-spacing: -0.63px; line-height: 34px; font-family: 'NSKB';text-align: center;
         &.pt92{padding-top:92px;}         
         em{background: linear-gradient(110deg, #00ebff -13%, #0aa5ff 42%, #8168ff); -webkit-background-clip: text; -webkit-text-fill-color: transparent;}
      }
      .bgBox{background-color: #fafbfc; padding: 60px 20px 80px 20px;}
      .nobgBox{padding: 60px 20px 80px 20px;}
      .videoBox{position: relative; height: 298px; border-radius: 5px; box-sizing: border-box;
         video{object-fit: cover;object-position: center;border-radius: 10px;}    
         .bg{position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 240px; height: 195px; background: url('../../images/kor/sub/brand/img-h-2-o@2x.png') no-repeat center center; background-size: cover;}
      }
      .imgLogo{margin:43px 0 120px 0; height: 24px; background: url('../../images/kor/sub/brand/logo@2x.png') no-repeat 50% center; background-size:contain;}
      .soulutionCon{position:relative; height:742px; background: url('../../images/kor/sub/brand/img_combination@2x.png') no-repeat top 150px center; background-size: 360px 605px;}
      .slideBox{position: relative; width: 100%; height: 350px; overflow: hidden;}
   }
}
#BrandSub02{position:relative;
   //브랜드 스토리
   .stroy{
      .h01{height: 1106px; padding-left: 20px; padding-right: 20px;}
      .stroyImg01{position: relative; margin:60px auto 0;
         .bg01{width: 100%; height: 450px; background: url('../../images/kor/sub/brand/img_01@2x.png') no-repeat 50%; background-size: cover;}
         .bg02{width: 100%; height: 450px; background: url('../../images/kor/sub/brand/img_02@2x.png') no-repeat 50%; background-size: cover;}
         .text{position: absolute; top: 50px; left: 50%; transform: translateX(-50%); width: 218px; height: 785px; background: url('../../images/kor/sub/brand/img_brandstory_01@2x.png') no-repeat center center; background-size: cover;}
      }
      .box03{padding-bottom: 20px; box-shadow: 4px 6px 16px 0 rgba(224, 229, 250, 0.5);
         &.mt505{margin-top: 505px;}
         .boxTitle{height: 46px; line-height: 46px; align-items: center; font-size: 16px; font-family: 'NSKB'; letter-spacing: -0.4px; border-bottom: 1px solid #dfe5f4;
            em{margin-left: 4px; font-size: 13px; letter-spacing: -0.33px; font-family: 'NSKR';}
         }
         .boxTxt{padding-top: 10px; font-size: 13px; font-weight: 500; letter-spacing: -0.33; line-height: 1.54; color: #575e70;}
      }
      .bg{z-index: -2; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-image: linear-gradient(to bottom, #fafbfc 97%, #ffffff);}
      .stroyImg02{z-index: -1; position: absolute; top: 42px; left: 50%; transform: translateX(-50%); width: 100%; height: 826px; background: url('../../images/kor/sub/brand/img_brandstory_02@2x.png') no-repeat center center; background-size: 360px 820px;}
      
      .stroyImg03{position: relative;width: 360px; height:858px; left: 50%;transform: translateX(-50%); margin:60px 0 0 0; background: url('../../images/kor/sub/brand/img_brandstory_03@2x.png') no-repeat 50%; background-size: cover;}

      // [개발검수0920] 이미지 사이즈 및 텍스트 위치 수정
      .stroyImg04{display: grid; justify-content: center;
         .hLine{width: 1px; height: 60px; background: #bbcbe8; margin:32px auto 24px;}
         .img01{margin: 25px auto 0; width: 250px; height:250px; background: url('../../images/kor/sub/brand/img_vision_convergence@2x.png') no-repeat center center; background-size: 250px;}
         .img02{margin: 10px auto 0; width: 250px; height:250px; background: url('../../images/kor/sub/brand/img_vision_connect@2x.png') no-repeat center center; background-size: 250px;}
         .img03{margin: 10px auto 0; width: 250px; height:250px; background: url('../../images/kor/sub/brand/img_vision_share@2x.png') no-repeat center center; background-size: 250px;}

         dl{position: relative; top: -10px; padding:35px 0 0 0; background: url('../../images/kor/sub/brand/cut3_arr@2x.png') no-repeat top left 50%; background-size: 38px 18px; text-align: center;
            dt{font-size: 18px; letter-spacing: -0.45px; font-family: 'NSKB'; padding-bottom:5px;}
            dd{font-size: 15px; letter-spacing: -0.38px;}
         }
      }

      .stroyImg05{display: grid; margin-top:60px; padding-bottom: 40px; justify-content: center; background-color: #fafbfc;
         .img01{width:360px; height:676px; margin:48px 0 0 0;background: url('../../images/kor/sub/brand/img_brandstory_05@2x.png') no-repeat 50%; background-size: cover;}
      }

      .stroyImg06{position: relative; width: 100%; background: #ecf2f8;
         &.h02{padding: 0 20px 0;}
         .box01{height: 200px; box-sizing: border-box;
            .boxTitle{height: 54px; padding: 5px 0 0 68px; text-align: left; font-size: 17px; font-family: 'NSKB'; letter-spacing: -0.43px;
               > div{margin-top: 3px; font-size: 15px; font-family: 'NSKR'; letter-spacing: -0.38px; color: #767676;}
               &.ic1{background: url('../../images/kor/sub/brand/ico_effect_1@2x.png') no-repeat left; background-size: 54px 54px;}
               &.ic2{background: url('../../images/kor/sub/brand/ico_effect_2@2x.png') no-repeat left; background-size: 54px 54px;}
               &.ic3{background: url('../../images/kor/sub/brand/ico_effect_3@2x.png') no-repeat left; background-size: 54px 54px;}
               &.ic4{background: url('../../images/kor/sub/brand/ico_effect_4@2x.png') no-repeat left; background-size: 54px 54px;}
            }
            .txt{margin-top: 15px; padding-top: 15px; font-size: 15px; line-height: 1.47; letter-spacing: -0.38px; text-align: left; border-top: 1px solid #dfe5f4;
               em{margin-left: 4px; font-family: 'NSKB';}
            }
         }
      }
      .btm{padding: 60px 20px 0 0; background: #ecf2f8;
         .engText{text-align: left;}
         .bText{text-align: left; font-family: 'NSKR';
            div{font-family: 'NSKB';}
         }
      }
      .brandBtn{position: absolute; bottom: 70px; left: 50%; transform: translateX(-50%); width: 206px;height: 48px;margin: 0 auto;border-radius: 100px;box-shadow: 0 3px 10px 0 rgba(123, 123, 123, 0.2);background-image: linear-gradient(to right, #0af 0%, #8168ff 100%);text-align:center;
         .txt{padding:0 16px 0 0;font-size:15px;line-height:48px;color:#ffffff;letter-spacing:-0.38px;text-align:center;box-sizing: border-box;
            background:url('../../images/kor/ico/ico-arrow-right-w@2x.png') no-repeat right;background-size:14px;
         }
      }
      .banner{width: 100%; height: 427px; padding-top: 20px; background: #ecf2f8; box-sizing: content-box;
         img{width: 100%;}
      }
   }
   //브랜드 세일즈 킷
   .sales{padding:0 20px;
      .title{font-size: 19px; font-family: 'NSKB'; letter-spacing: -0.48px; color: #222; border-bottom: solid 1px #dbdbdb; padding:0 0 14px 0;}

      .box01{height: auto; padding-bottom: 40px;  border: solid 1px #e8e8e8; border-top: 3px solid #607fff; box-sizing: border-box;
         .boxTitle{padding: 5px 0 0 0; text-align: left; font-size: 17px; font-family: 'NSKB'; letter-spacing: -0.43px;
            > div{margin-top: 3px; font-size: 15px; font-family: 'NSKR'; letter-spacing: -0.38px; color: #767676;}
         }
         .txt{margin-top: 15px; padding-top: 15px; font-size: 15px; line-height: 1.47; letter-spacing: -0.38px; text-align: left; border-top: 1px solid #dfe5f4; color:#484848;
            em{color: #4d7cfe; font-family: 'NSKB';}
         }
      }
      .salesImg01{margin-top:70px;
         .img01{margin-top:30px; height: 211px; background:#f7f7f7 url('../../images/kor/sub/brand/img_saleskit_01@2x.png') no-repeat 50%; background-size: contain;}
      }   
      .salesImg02{margin:80px 0 70px 0;
         .img01{margin-top:30px; height: 292px; background: url('../../images/kor/sub/brand/img_saleskit_02@2x.png') no-repeat 50%; background-size: contain;}
         .img02{margin-top:60px; height: 293px; background: url('../../images/kor/sub/brand/img_saleskit_03@2x.png') no-repeat 50%; background-size: contain;}
         .img03{margin-top:60px; height: 293px; background: url('../../images/kor/sub/brand/img_saleskit_04@2x.png') no-repeat 50%; background-size: contain;}
         .img04{margin-top:60px; height: 293px; background: url('../../images/kor/sub/brand/img_saleskit_05@2x.png') no-repeat 50%; background-size: contain;}
      }   
   }
   //브랜드 캐릭터
   .character{padding:0 20px;
      .characterCon{padding-top:70px;
         .title{font-size: 19px; font-family: 'NSKB'; letter-spacing: -0.48px; color: #222; border-bottom: solid 1px #dbdbdb; padding:0 0 14px 0;}
         .text{font-size: 16px; letter-spacing: -0.4px; padding:16px 0 0 0;line-height: 24px;}
         .conText{font-size: 15px; line-height: 1.6; letter-spacing: -0.38px; text-align: center; color: #484848;}
         .radiusBox{text-align: center; margin:60px 0 20px 0; justify-content: center; align-items: center; display: flex;
            > span {max-width: max-content; padding: 8px 24px; border-radius: 100px;letter-spacing: -0.35px;font-size: 14px;}
            &:first-child{margin-top:0px;}
            .radius01{background-color:#feefd8; color: #dc8c3a;}
            .radius02{background-color:#ebecec; color: #616366;}
            .radius03{background-color:#edf3f7; color: #575e70;}
            .radius04{background-color:#eff2ff; color: #607fff;}
            .radius05{background-color:#607fff; color: #fff;}
            .radius06{margin:0px 0 10px 0; background-color:#eff2ff; color: #607fff;}
            .radius07{background-color:#eff2ff; color: #607fff;}
         }  
         .img01{margin-top:30px; height: 311px; background: url('../../images/kor/sub/brand/img_character_01@2x.png') no-repeat 50%; background-size: contain;}
         .img02{margin-top:30px; height: 400px; background: url('../../images/kor/sub/brand/img_character_02@2x.png') no-repeat 50%; background-size: contain;} 
         .img03{margin-top:60px; height: 311px; background: url('../../images/kor/sub/brand/img_character_03@2x.png') no-repeat 50%; background-size: contain;}
         .img04{margin-top:30px; height: 400px; background: url('../../images/kor/sub/brand/img_character_04@2x.png') no-repeat 50%; background-size: contain;}  
         .img05{margin-top:60px; height: 311px; background: url('../../images/kor/sub/brand/img_character_05@2x.png') no-repeat 50%; background-size: contain;}
         .img06{margin-top:30px; height: 400px; background: url('../../images/kor/sub/brand/img_character_06@2x.png') no-repeat 50%; background-size: contain;}  
         .img07{height: 290px; background: url('../../images/kor/sub/brand/img_character_07@2x.png') no-repeat 50%; background-size: contain;}
         .img08{height: 224px; background: url('../../images/kor/sub/brand/img_character_08@2x.png') no-repeat 50%; background-size: contain;} 
         .img09{margin-bottom:80px; height: 243px; background: url('../../images/kor/sub/brand/img_character_09@2x.png') no-repeat 50%; background-size: contain;}   
         
         .img10{height: 280px; background: url('../../images/kor/sub/brand/img_character_human_01@2x.png') no-repeat 50%; background-size: contain;}  
         .img11{height: 280px; background: url('../../images/kor/sub/brand/img_character_human_02@2x.png') no-repeat 50%; background-size: contain;}  
         .img12{height: 232px; background: url('../../images/kor/sub/brand/img_character_human_03@2x.png') no-repeat 50%; background-size: contain;}  
         .img13{margin-top:50px; height: 262px; background: url('../../images/kor/sub/brand/img_character_human_04@2x.png') no-repeat 50%; background-size: contain;}  
         .img14{height: 262px; background: url('../../images/kor/sub/brand/img_character_human_05@2x.png') no-repeat 50%; background-size: contain;} 
         .img15{margin:20px 0 30px 0; height: 262px; background: url('../../images/kor/sub/brand/img_character_human_06@2x.png') no-repeat 50%; background-size: contain;} 
      }
   }
}
#BrandSub03{position:relative;
   .bi{padding:0 20px;
      .title{font-size: 19px; font-family: 'NSKB'; letter-spacing: -0.48px; color: #222; border-bottom: solid 1px #dbdbdb; padding:80px 0 14px 0;}
      .text{font-size: 16px; letter-spacing: -0.4px; padding:16px 0 0 0;line-height: 24px;}
      .sTitle{font-size: 17px; padding:30px 0 16px 0;font-family: 'NSKB';}
      .gradient{width:320px;margin:0 auto;
         span{font-size: 14px;}
      }
      .radiusBox{text-align: center; margin:20px 0; justify-content: center; align-items: center; display: flex;
         > span {max-width: max-content; padding: 9px 85px; border-radius: 110px; letter-spacing: -0.38px; font-size: 15px; box-shadow: 0 4px 10px 0 rgba(224, 229, 250, 0.8); border: solid 1px #607fff; background-color: #fff;color: #607fff; font-family: 'NSKB';}
      }
      .conText{font-size:15px; line-height: 22px; letter-spacing: -0.38px;text-align: center;}
      .img01{margin-top:24px; height: 84px; background: url('../../images/kor/sub/brand/img_bi_01@2x.png') no-repeat 50%; background-size: contain;}
      .img02{margin-top:50px; height: 645px; background: url('../../images/kor/sub/brand/img_bi_02@2x.png') no-repeat 50%; background-size: contain;}
      .img03{margin-top:33px; height: 678px; background: url('../../images/kor/sub/brand/img_bi_03@2x.png') no-repeat 50%; background-size: contain;}
      .img04{margin:0 auto;width:320px; height: 138px; background: url('../../images/kor/sub/brand/img_color_blue@2x.png') no-repeat 50%; background-size: cover;}
      .img05{margin:0 auto;margin-top:16px; width:320px; height: 138px; background: url('../../images/kor/sub/brand/img_color_purple@2x.png') no-repeat 50%; background-size: cover;}
      .img06{width:320px; height: 42px; background: url('../../images/kor/sub/brand/img_color_gradient@2x.png') no-repeat 50%; background-size: contain;}
      .img07{margin-top:50px; height: 174px; background: url('../../images/kor/sub/brand/img_bi_04@2x.png') no-repeat 50%; background-size: contain;}
      .img08{/*width:38px;*/ height:38px; margin:40px 0 32px 0; background: url('../../images/kor/sub/brand/img_plus@2x.png') no-repeat 50%; background-size: contain;}
      .img09{height: 179px; background: url('../../images/kor/sub/brand/img_bi_05@2x.png') no-repeat 50%; background-size: contain;}
      .img10{height: 174px; background: url('../../images/kor/sub/brand/img_bi_06@2x.png') no-repeat 50%; background-size: contain;}
      .img11{margin-top:40px; height: 276px; background: url('../../images/kor/sub/brand/img_bi_07@2x.png') no-repeat 50%; background-size: contain;}
      .img12{margin-top:20px; height: 343px; background: url('../../images/kor/sub/brand/img_bi_08@2x.png') no-repeat 50%; background-size: contain;}
      .img13{margin-top:20px; height: 399px; background: url('../../images/kor/sub/brand/img_bi_09@2x.png') no-repeat 50%; background-size: contain;}
      .img14{height: 216px; background: url('../../images/kor/sub/brand/img_bi_10@2x.png') no-repeat 50%; background-size: contain;}
      .img15{height: 215px; background: url('../../images/kor/sub/brand/img_bi_11@2x.png') no-repeat 50%; background-size: contain;}
      .img16{margin-bottom:70px; height: 215px; background: url('../../images/kor/sub/brand/img_bi_12@2x.png') no-repeat 50%; background-size: contain;}
   }
}

/**************************************************
   Service (서비스소개)
**************************************************/
#ServiceSub01, #ServiceSub02, #ServiceSub03, #ServiceSub04,
#ServiceSub05, #ServiceSub06, #ServiceSub07, #ServiceSub08, #ServiceSub09{position:relative;
   .allBox{position: relative; padding: 60px 46px; box-sizing: border-box;
      &.h01{height: 610px;}
      &.h02{height: 660px;}
      &.h03{height: 580px;}
      &.h04{height: 700px;}
      &.h05{height: 630px;}
      &.bg{background-color: #f7f8f8; z-index: 0;}
      &.txtR{text-align: right;}
      .title{font-size: 25px; font-family: 'NSKB'; line-height: 1.36; letter-spacing: -0.62px; color: #121212;
         .gray{color: #8b8b8f;
            em{margin-left: 5px; color: #121212;}
         }
         em{margin-left: 5px; color: #8b8b8f;}
      }
      .txt{margin-top: 7px; font-size: 16px; line-height: 1.5; letter-spacing: -0.4px; color: #454545;}
      .serviceBtn{width: 148px; height: 36px; padding-right: 15px; margin-top: 18px; font-size: 14px; font-family: 'NSKB'; letter-spacing: -0.35px; color: #4d7cfe; border: 1px solid #4d7cfe; border-radius: 30px; background: transparent url('../../images/kor/ico/ico-arrow-right-blue@2x.png') no-repeat right 15px center; background-size: 14px;
         &:active{background-color: rgba(77, 124, 254, 0.1);}
         &:disabled{color: #8b8b8f; border: 1px solid #ababac; background: #fcfcfc url('../../images/kor/ico/ico-arrow-right-blue-disabled@2x.png') no-repeat right 15px center; background-size: 14px;}
      }
      .img01{z-index: -1; position: absolute; bottom: 0; right: 0; width: 360px; height: 440px;}
      .img02{z-index: -1; position: absolute; bottom: 0; left: 0; width: 360px; height: 440px;}
      .img03{z-index: -1; position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); width: 360px; height: 440px;}
   }
}

/**************************************************
   Introduction (도입안내)
**************************************************/
#IntroductionSub01{position:relative; box-sizing: border-box;
   .inner{padding: 60px 20px;    
      .title{margin: 18px 0 40px; text-align: center; font-size: 25px; font-family: 'NSKB'; letter-spacing: -0.63px;}
      .box01{
         .conIco{width: 88px; height: 89px; margin: 0 auto; background: url('../../images/kor/ico/ico-constructive@2x.png') no-repeat center center; background-size: 88px 89px;}
         .cloIco{width: 88px; height: 89px; margin: 0 auto; background: url('../../images/kor/ico/ico-cloud@2x.png') no-repeat center center; background-size: 88px 89px;}
         .boxTitle{font-size: 21px; font-family: 'NSKB'; letter-spacing: -0.53px; color: #222222;}
         .txt{margin-top: 16px; padding-top: 16px; font-size: 16px; line-height: 1.5; letter-spacing: -0.4px; border-top: 1px solid #dfe5f4; color: #484848;}
         &:first-of-type{margin-top: 0;}
      }
      .box02{
         // [개발검수0920] 서비스특징 박스 타이틀 택스트, 블릿 위치 센터 정렬
         .boxTitle{width: 100%; height: 62px; padding: 0 24px 20px 42px; line-height: 60px; font-size: 19px; font-family: 'NSKB'; letter-spacing: -0.48px; color: #222222; border-radius: 6px 6px 0 0; border-bottom: 1px solid #b5c8ff; background: #f2f6fd url('../../images/kor/ico/ico-bullet@2x.png') no-repeat left 23px center; background-size: 8px; box-sizing: border-box;}
         // [개발검수0908] 컨텐츠깨짐 수정
         .tBox{margin:0;padding: 22px 24px;border:none;box-sizing: border-box;
            .txt01{font-size: 16px; font-family: 'NSKB'; letter-spacing: -0.4px;
               // [개발검수1107] 다국어 수정 
               em{vertical-align: text-top; line-height:24px; margin-right: 8px; font-size: 14px; letter-spacing: -0.35px; color: #607fff;}
            }
            .txt02{position: relative; margin: 6px 0 14px; padding-left: 12px; font-size: 15px; line-height: 1.53; letter-spacing: -0.38px; color: #484848;
               &:last-of-type{margin-bottom: 0;}
               &::before{content: ''; position: absolute; top: 9px; left: 0; width: 3px; height: 3px; border-radius: 5px; background-color: #484848;}
            }
         }
         &:first-of-type{margin-top: 0;}
      }
      .box03{
         // [개발검수0920] 서비스특징 박스 타이틀 택스트, 블릿 위치 센터 정렬
         .boxTitle{width: 100%; height: 67px; padding: 0 0 0 18px; line-height: 65px; font-size: 19px; font-family: 'NSKB'; letter-spacing: -0.48px; color: #222222; border-bottom: 1px solid #dfe5f4; background: url('../../images/kor/ico/ico-bullet@2x.png') no-repeat left center; background-size: 8px; box-sizing: border-box;}
         .txt01{margin: 18px 0 4px; font-size: 16px; font-family: 'NSKB'; letter-spacing: -0.4px;
            em{vertical-align: text-top; line-height: 1.38; margin-right: 8px; font-size: 14px; letter-spacing: -0.35px; color: #607fff;}
         }
         .txt02{position: relative; margin-top: 4px; padding-left: 12px; font-size: 15px; line-height: 1.53; letter-spacing: -0.38px; color: #484848;
            &:first-of-type{margin-top: 0;}
            &::before{content: ''; position: absolute; top: 9px; left: 0; width: 3px; height: 3px; border-radius: 5px; background-color: #484848;}
         }
         &:first-of-type{margin-top: 0;}
      }
      &.bg{padding: 60px 20px 70px; background-image: linear-gradient(180deg, #f7fbff 95%, #f9fafb);}
   }
}
#IntroductionSub02{position:relative;}
#IntroductionSub03{position:relative; box-sizing: border-box;
   .inner{padding: 60px 20px 70px;
      .box{width: 100%; margin: 30px auto 0;
         .part{margin-bottom: 12px; font-size: 16px; font-family: 'NSKB'; letter-spacing: -0.4px;
            em{color: #607fff;}
         }
         .boxTxt{margin-top: 12px; text-align: left; font-size: 15px; line-height: 1.6; letter-spacing: -0.38px; color: #8b8b8f;}
      }
      // [개발검수0922] input 폰트 사이즈 변경
      .fileBox{position: relative; align-items: center;
         .upLoadName{width: 100%; height: 44px; padding: 10px 16px; border: 1px solid #dbdbdb; border-radius: 0; outline: none; font-size: 16px; letter-spacing: -0.35px; box-sizing: border-box;}
         label{width: 78px; height: 44px; line-height:42px;text-align: center; margin-left: 6px; border: 1px solid #b9b9b9; border-radius: 4px; font-size: 16px; font-family: 'NSKB'; letter-spacing: -0.38px; color: #4a4a4a; box-sizing: border-box;}
         input[type="file"]{position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; border: none;}
      }
   }
}

/**************************************************
   Media (미디어센터)
**************************************************/
#MediaSub01, #MediaSub03{position:relative;
   .box{padding: 60px 0;
      .title{font-size: 25px; font-family: 'NSKB'; letter-spacing: -0.63px; text-align: center;}
      .mediaSelect{padding: 0 20px; margin-top: 40px;}
      &:last-of-type{padding-bottom: 70px;}
   }
}
#MediaSub02{position:relative; padding-bottom: 70px;
   .title{font-size: 25px; font-family: 'NSKB'; letter-spacing: -0.63px; text-align: center;}
   .reportAllBox{margin-top: 40px; border-top: 1px solid #6c6c6c; box-sizing: border-box;
      .reportBox{padding: 16px 0; border-bottom: 1px solid #e5e5e5; box-sizing: border-box;
         .tit{
            p{font-size: 16px; font-family: 'NSKB'; line-height: 1.44; letter-spacing: -0.72px;}
            .reportIcon{width: 18px; height: 18px; margin: 2px 0 0 10px; background: url('../../images/kor/sub/media/ico-link@2x.png') no-repeat center center; background-size: 18px;}
         }
         .reportText01{min-width: 320px; height: 45px; margin-top: 5px; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; font-size: 15px; line-height: 1.53; letter-spacing: -0.38px; color: #484848;}
         .reportText02{margin-top: 6px; font-size: 14px; font-weight: 500; letter-spacing: -0.63px; color: #ababac;
            > div{
               &::after{content: ''; display: inline-block; width: 1px; height: 10px; margin: 0 8px; background-color: #e5e5e5;}
            }
         }
      }
   }  
}
#MediaSub04{position: relative; padding-bottom: 70px;
   .inner{padding-left: 20px; padding-right: 20px;}
   .top{margin-top: 40px;
      .selectBox{
         .inbox{padding: 10px 0 0 10px;
            .arr{right: 8px;}
         }
      }
      // [개발검수0922] input 폰트 사이즈 변경
      .searchBox{margin-left: 6px; font-size: 16px;}
   }
   // [개발검수1107] 다국어 수정 
   .media04Table{width: 100%; margin-top: 20px; border-top: 1px solid #6c6c6c;
      tr{vertical-align: text-top; border-bottom: 1px solid #e5e5e5; box-sizing: border-box;
         th{text-align: left; font-size: 16px; letter-spacing: -0.4px; font-weight: normal;
            .label{text-align: center; font-size: 16px; font-weight: 500; font-family: 'NSKB'; color: #4d7cfe;}
         }
         td{padding: 14px 6px; text-align: left; font-size: 16px;
            .txtGray{margin-top: 6px; font-size: 14px; letter-spacing: -0.35px; color: #7b7b7b;
               em{
                  &::before{content: ''; display: inline-block; width: 1px; height: 10px; background-color: #e5e5e5; margin-left: 8px; margin-right: 8px;}
               }
            }   
            &.emptyTd{text-align: center; padding: 40px 0; font-size: 16px; letter-spacing: -0.45px; color: #7b7b7b;}
            .attach{display: inline-block; width: 18px; height: 18px; margin-left: 10px; background: url('../../images/kor/sub/media/icon-file@2x.png') no-repeat center center; background-size: 18px;}
         }
         &:first-child{border-bottom: none;}
      }
   }
   .tableCon{padding: 60px 20px 0px; background-color: #ffffff;
      // [개발검수0908] 파일리스트 수정
      .file{min-width: 18px;width: 18px; height: 18px; margin-right: 8px; background: url('../../images/kor/sub/media/icon-file@2x.png') no-repeat center center; background-size: 18px;}
      .ac{border-top: 2px solid #000000; border-bottom: 1px solid #e5e5e5; padding: 12px 0;
         .tableTit{text-align: left; font-size: 16px; font-weight: 500; letter-spacing: -0.7px;
            em{margin-right: 4px; color: #265cf0;}
         }
         .timeCheck{align-items: center; margin-top: 4px; font-size: 14px; letter-spacing: -0.35px; color: #ababac;
            .time{justify-content: flex-start;
               b{
                  &::before{content: ''; display: inline-block; width: 1px; height: 10px; margin: 0 8px; background-color: #e5e5e5;}
               }
            }
            em{margin-left: 5px;}
         }
         // [개발검수0908] 파일리스트 수정
         .boardTop{border-top: 1px solid #e5e5e5; padding-top: 12px; margin-top: 12px;
            .topAc{margin-top:10px;height: 20px; line-height: 20px; font-size: 14px; letter-spacing: -0.35px; align-items: center;
               > div{margin-right: 8px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}
               &:first-child{margin-top:0px;}
            }
            img{min-width: 18px;width: 18px; height: 18px;font-size:0;}
         }
      } 
      // [개발검수0908] 이미지사이즈 모바일제한 수정 
      .boardMid{padding: 20px 0;
         img{display:block; margin: 0 auto; width: 100% !important;height:auto !important;}
      }
      .boardBt{padding: 14px 0; border-top: 1px solid #e5e5e5; border-bottom: 1px solid #e5e5e5;
         .inven{font-size: 14px; letter-spacing: -0.35px;
            &:last-child{margin-top: 10px;}
            &.noAticle{color: #7b7b7b;}
            // [개발검수0908] 이전글/다음글 깨짐수정
            em{min-width:60px;padding-right: 22px; margin-right: 14px; color: #000000;
               &.prev{background: url('../../images/kor/sub/media/ic-next-article-disable@2x.png') no-repeat top 2px right; background-size: 18px;
                  &.on{background: url('../../images/kor/sub/media/ic-next-article-normal@2x.png') no-repeat top 2px right; background-size: 18px;}
               }
               &.next{background: url('../../images/kor/sub/media/ic-prev-article-disable@2x.png') no-repeat top 2px right; background-size: 18px;
                  &.on{background: url('../../images/kor/sub/media/ic-prev-article-normal@2x.png') no-repeat top 2px right; background-size: 18px;}
               }
            }
            b{text-align: right; color: #7b7b7b; font-weight: normal;}
         }
      }
      button{width: 124px; height: 48px; margin: 30px auto 0; font-size: 15px; font-weight: 500; letter-spacing: -0.38px; color: #ffffff; outline: none; border: none; border-radius: 50px; background-image: linear-gradient(71deg, #607fff 4%, #786eff 94%); box-shadow: 0 3px 10px 0 rgba(123, 123, 123, 0.2);
         &:active{background-image: linear-gradient(82deg, #5472ef 4%, #5a50e0 95%);}
      }
   }
}
#MediaSub05{position: relative;
   .inner{padding-bottom:70px;
      &.bg{background-color:#fafbfc;}
      .cStoryBox{width:320px;margin:24px auto 0 auto;border:1px solid #e5e5e5;border-radius:10px;overflow:hidden;background-color:#ffffff;box-sizing: border-box;
         .topBox{position:relative;width:318px;height:177px;
            &.img01{background:url('../../images/kor/sub/media/img-card-01@2x.png') no-repeat top;background-size:contain;}
            &.img02{background:url('../../images/kor/sub/media/img-card-02@2x.png') no-repeat top;background-size:contain;}
            &.img03{background:url('../../images/kor/sub/media/img-card-03@2x.png') no-repeat top;background-size:contain;}
            &.img04{background:url('../../images/kor/sub/media/img-card-04@2x.png') no-repeat top;background-size:contain;}
            &.img05{background:url('../../images/kor/sub/media/img-card-05@2x.png') no-repeat top;background-size:contain;}
            &.img06{background:url('../../images/kor/sub/media/img-card-06@2x.png') no-repeat top;background-size:contain;}
            .badge{position:absolute;top:20px;left:20px;padding:0 16px;height:29px;line-height:27px;font-size:13px;font-family:'NSKB';color:#ffffff;border:1px solid #ffffff;border-radius:29px;box-sizing: border-box;background-color:rgba(0, 0, 0, 0.15);}
         }
         .midBox{padding:20px 20px 0 24px;
            .tagText{line-height:20px;font-size:14px;font-family:'NSKR';color:#607fff;letter-spacing:-0.14px;}
            .cTxt01{line-height:30px;font-size:21px;font-family:'NSKB';color:#222222;letter-spacing:-0.21px;}
            .cTxt02{margin:10px 0 0 0;line-height:24px;font-size:16px;font-family:'NSKR';color:#222222;letter-spacing:-0.4px;
               .marker{position:relative;font-family:'NSKB';box-shadow: inset 0 -8px 0 rgba(77, 124, 254, 0.2);}
            }
         }
         .botBox{height:56px;margin:14px 24px 0 24px;border-top:1px solid rgba(219, 219, 219, 0.5);line-height:45px;text-align:right;font-size:14px;font-family:'NSKR';color:#8b8b8f;letter-spacing:-0.35px;}
      }
      .cLogoBox{width:320px;margin:0 auto;
         .logoTitle{margin:10px 0 18px 0;line-height:27px;font-size:18px;font-family:'NSKB';color:#000000;letter-spacing:-0.45px;}
         .logoFlexBox{margin:0 0 30px 0;flex-wrap:wrap;
            .logoBox{margin: -1px 0 0 -1px;width: 160px;height: 64px;border: 1px solid #e5e5e5;justify-content: center;align-items: center;background-color:#ffffff;
               img{width:140px;height:44px;}
            }
         }
      }
   }
}

/**************************************************
   VirtualEx (가상체험관)
**************************************************/
#VirtualExSub01{position:relative; padding-bottom: 10px;
   .inner{
      &.h01{padding-bottom: 60px;}
      &.h02{height: 701px;}
   }
   // [개발검수1107] 다국어 수정 
   .slideBox{position: relative; overflow: hidden; width: 100%; height: 462px;}
   .bg{display: block; width: 100%; margin-top: 40px;}
   .virtualBtn{width: 206px;height: 48px;margin:0 auto;border-radius: 100px;box-shadow: 0 3px 10px 0 rgba(123, 123, 123, 0.2);background-image: linear-gradient(to right, #0af 0%, #8168ff 100%);text-align:center;
      .txt{padding:0 26px 0 0;font-size:15px;line-height:48px;color:#ffffff;letter-spacing:-0.38px;text-align:center;box-sizing: border-box;
         background:url('../../images/kor/sub/virtualEx/ico_link_w@2x.png') no-repeat right;background-size:24px;
      }
   }
}

/**************************************************
   ceo (ceo 인사이트)
**************************************************/
#CeoSub01, #CeoSub02, #CeoSub03{
   .inner{padding:60px 20px 70px 20px;
      &.forumIn{padding-bottom: 0px;}
      &.bgColor{background-color: #fafbfc;}
      .dxTitle{font-size: 19px; font-family: 'NSKB'; letter-spacing: -0.48px; color: #222222; padding: 70px 0 14px; border-bottom: 1px solid #dbdbdb;
         &.nonBd{border-bottom: none;}
      }
      .dxTxt{margin-top: 16px; margin-bottom: 10px; font-size: 16px; letter-spacing: -0.4px;
         em{color: #607fff;}
      }
      .box{width: 100%; margin: 30px auto 0;
         .part{margin-bottom: 12px; font-size: 16px; font-family: 'NSKB'; letter-spacing: -0.4px;
            em{color: #607fff;}
         }
         .boxTxt{margin-top: 12px; text-align: left; font-size: 15px; line-height: 1.6; letter-spacing: -0.38px; color: #8b8b8f;}
         .dxAgree{margin-top: 12px; font-size: 15px; letter-spacing: -0.38px; color: #8b8b8f;}
      }
      .dxBox{margin-top: 30px; padding-top: 20px; text-align: center; border-radius: 4px; border: 1px solid #dbdbdb; background-color: #f2f6fd; overflow: hidden;
         .boxTxt01{font-size: 15px; font-family: 'NSKB'; letter-spacing: -0.38px; color: #4d7cfe;}
         .boxTxt02{align-items: center; justify-content: center; margin-bottom: 18px; font-size: 16px; font-family: 'NSKB'; letter-spacing: -0.4px; color: #222222;
            .boxArr{display: block; width: 18px; height: 18px; background: url('../../images/kor/sub/ceo/ico-arrow-right-l@2x.png') no-repeat center center; background-size: 18px;}
         }
         img{width: 100%;}
      }
      .acc{display: block; width: 174px; height: 46px; margin: 40px auto 0; background: url('../../images/kor/sub/ceo/img-arrow@2x.png') no-repeat center center; background-size: 174px 46px;}
      .bg01{display: block; width: 290px; height: 290px; margin: 10px auto 0; background: url('../../images/kor/sub/ceo/img-dx-insight@2x.png') no-repeat center center; background-size: 290px;}
      .bg02{display: block; width: 100%; height: 1162px; margin: 20px auto 0; background: url('../../images/kor/sub/ceo/ceoinsight-inforgraphic@2x.png') no-repeat center center; background-size: 360px 1162px;}
      .btTxt{text-align: center; font-size: 15px; font-weight: 500; letter-spacing: -0.38px; line-height: 1.53; color: #4a4a4a;
         em{color: #607fff;}
      }
      .insightBox{width: 320px; height: 564px; padding: 24px; margin: 18px auto 0; border-radius: 6px; box-shadow: 0 3px 16px 0 rgba(87, 104, 175, 0.08); border: solid 1px #e8e8e8; background-color: #ffffff; box-sizing: border-box;
         img{width: 272px; height: 376px; border: 1px solid #e3e3e3; border-radius: 4px; overflow: hidden; box-sizing: border-box;}
         .insightTit{margin-top: 14px; font-size: 16px; font-family: 'NSKB'; letter-spacing: -0.4px;}
         .insightTxt{margin-top: 4px; font-size: 14px; font-weight: 500; letter-spacing: -0.35px; color: #ababac;}
         .nonImg{width: 272px; height: 376px; padding: 170px 78px; font-size: 24px; font-family: 'NSKB'; letter-spacing: -0.24px; color: #c1c1c1; border-radius: 4px; border: solid 1px #e3e3e3; background-color: #f9fafb; overflow: hidden;}
      }
      .insightBtn{display: block; margin: 40px auto 0; width: 208px; height: 48px; font-size: 15px; font-weight: 500; letter-spacing: -0.38px; color: #222222; border: 1px solid #222222; border-radius: 100px; background-color: rgba(255, 255, 255, 0.1);}
      .forumBox{
         .forum{position: relative; width: 320px; height: 377px; margin: 18px auto 0; border-radius: 10px; border: 1px solid #e3e3e3; box-sizing: border-box; overflow: hidden;
            .forumLabel{position: absolute; top: 12px; left: 12px; padding: 6px 16px; font-size: 11px; font-family: 'NSKB'; text-align: center; border-radius: 20px;
               &.ok{background-color: #4d7cfe; color: #ffffff;}
               &.no{background-color: #e5e5e5; color: #7b7b7b;}
            }
            .forumImg{width: 320px; height: 177px;}
            .txtBox{padding: 20px 24px 0;
               .forumTit{font-size: 16px; font-family: 'NSKB'; letter-spacing: -0.16px; color: #222222;}
               p{padding: 5px 0 18px; border-bottom: 1px solid rgba(219, 219, 219, 0.5); font-size: 15px; line-height: 1.53; letter-spacing: -0.38px; color: #8b8b8f;}
               .time{padding-top: 18px; font-size: 15px; font-weight: 500; letter-spacing: -0.38px; color: #607fff;}
            }
         }
         .box{position: relative; width: 320px; height: 178px;
            // [개발검수0908] 이미지 비율 수정 // [개발검수1102] 이미지 둥근 모서리
            img{display: block; width: 100% !important;height:auto !important; margin: 0 auto;border-radius: 6px;}
            .play{position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 70px; height: 70px; background: url('../../images/kor/sub/media/btn-play@2x.png') no-repeat center center; background-size: 70px;}
         }
         .forumTxtBox{width: 320px; margin: 14px auto 0;
            .forumTxt{font-size: 14px; font-weight: 500; letter-spacing: -0.35px; color: #ababac;}
            .forumTit{font-size: 16px; font-family: 'NSKB'; letter-spacing: -0.4px;}
         }
      }
      .forumTable{width: 100%; border-top: 1px solid #6c6c6c; box-sizing: border-box;
         tr{border-bottom: 1px solid #e5e5e5; font-size: 16px; letter-spacing: -0.4px; box-sizing: border-box;
            th{width: 80px; background-color: #fafbfc;}
            td{padding: 12px 18px;
               em{font-family: 'NSKB';}
               div{font-size: 14px; letter-spacing: -0.35px; color: #ababac;
                  &.blue{color: #4d7cfe; margin-top: 4px;}
               }
            }
         }
      }
      // [개발검수0908] 컨텐츠 이미지 깨짐 수정
      .forumImgBox{margin-top: 30px;
         img{display: block; width: 100% !important;height:auto !important; margin: 0 auto;}
      }
      .completeIco{display: block; width: 60px; height: 60px; margin: 0 auto; background: url('../../images/kor/sub/ceo/ic-complete@2x.png') no-repeat center center; background-size: 60px;}
      .completeTxt{font-size: 16px; line-height: 1.5; letter-spacing: -0.4px; color: #8b8b8f; text-align: center; margin-top: 14px;}
   }
   .scrollList{margin-top: 30px; margin-bottom: 60px;}
   .btnBox{margin-top: 20px;
      &.forumBtn{margin-top: 30px; align-items: center;
         button{width: 222px;
            &:first-child{margin-right: 0;}
            &:last-child{margin-top: 14px;}
         }
      }
      &.forumBtn2{width: 282px; margin: 20px auto 10px;}
      &.dx03Btn{margin: 40px 0; justify-content: center;
         button{min-width:130px;
            &:first-child{margin-right: 5px;padding:13px 34px 13px 22px;}
            &:last-child{margin-left: 5px;padding:13px 44px 13px 32px;}
            &.sightArr{background: #ffffff url('../../images/kor/sub/ceo/ico-arrow-right-p@2x.png') no-repeat right 28px center; background-size: 14px;
               &.blue{color: #ffffff; background: #4d7cfe url('../../images/kor/sub/ceo/ico-arrow-right-w@2x.png') no-repeat right 18px center; background-size: 14px;}
            }
         }
      }
      button{padding: 13px 42px 13px 30px; font-size: 15px; font-weight: 500; letter-spacing: -0.38px; color: #4d7cfe; border: 1px solid #4d7cfe; border-radius: 100px; box-shadow: 0 3px 10px 0 rgba(123, 123, 123, 0.2); box-sizing: border-box;
         // [개발검수0920] 버튼 마진값 수정
         &.sightArr{background: #ffffff url('../../images/kor/sub/ceo/ico-arrow-right-p@2x.png') no-repeat right 24px center; background-size: 14px;
            &.blue{color: #ffffff; background: #4d7cfe url('../../images/kor/sub/ceo/ico-arrow-right-w@2x.png') no-repeat right 24px center; background-size: 14px;}
            &.disa{color: #8b8b8f; background: #e5e5e5 url('../../images/kor/sub/ceo/arrow-02@2x.png') no-repeat right 24px center; background-size: 15px; border: none; box-shadow: none;}
         }
         &.down{background: url('../../images/kor/sub/ceo/ico-download@2x.png') no-repeat right 24px center; background-size: 14px;}
      }
   }
   // [개발검수0920] 세미나 마감 및 종료 문구 추가
   .endBox{height: 88px; margin-top: 40px; border-radius: 6px; border: 1px solid #e5e5e5; background-color: #f9fafb; box-sizing: border-box;
      .error_gr{align-items: center; justify-content: center;
         span{display: inline-block; width: 22px; height: 22px; margin-right: 5px; background: url('../../images/kor/ico/ic_input_error_gr@2x.png') no-repeat center center; background-size: 22px;}
         p{text-align: center; font-size: 16px; font-weight: 500; line-height: 88px; letter-spacing: -0.4px; color: #8b8b8f;}
      }
   }
   .banner{position: relative; z-index: 0; padding: 40px 20px 60px; background-image: linear-gradient(28deg, #ecf2f8 2%, #f7f9fb 100%);
      &.bgC{background: #ecf2f9}
      .bannerT01{font-size: 14px; font-family: 'NSKB'; letter-spacing: -0.35px; color: #4d7cfe;}
      .bannerT02{margin-top: 10px; font-size: 22px; line-height: 1.45; letter-spacing: -0.55px;
         b{font-family: 'NSKB';}
      }
      img{width: 100%;
         &.forumBanner{position: absolute; z-index: -1; top: 0; bottom: 0; left: 50%; transform: translateX(-50%); width: 360px; height: 638px;}
      }
      .forumBtn{margin-top: 357px;}
   }
}

/**************************************************
   Terms (약관 및 정책)
**************************************************/
#TermsSub01, #TermsSub02, #TermsSub03{position:relative;
   .inner{padding: 60px 20px 70px;
      .menuTitle{font-size: 25px; font-family: 'NSKB'; letter-spacing: -0.63px;}
      .menuTxt{margin: 14px 0 30px; font-size: 16px; letter-spacing: -0.4px; color: #8b8b8f;}
      .box{margin-top: 50px; padding-bottom: 50px; border-bottom: 1px solid #e5e5e5;
         &.title{padding-bottom:10px;border-bottom: 1px solid #484848;
            .title{font-size:22px;}
         }
         &.borNo{border-bottom: none; padding-bottom: 0;}
         p{margin-top: 30px; font-size: 16px; line-height: 1.63; letter-spacing: -0.4px; color: #484848;
            &.blue{color: #4569ff;}
            &.mt0{margin-top: 0;}
         }
         // [개발검수1108] 다국어 수정 
         .disc{margin-top: 10px; font-size: 15px; line-height: 1.53; letter-spacing: -0.38px; color: #727272;}
         .conBox{margin-top: 30px; padding: 28px 20px;background-color:#fafbfc;
            .con{margin-top: 10px; font-size: 16px; line-height: 1.5; letter-spacing: -0.4px; color: #4a4a4a;
               em{font-family: 'NSKB'; margin-right: 17px;}
            }
         }
         .smallBox{margin-top: 35px; text-align: center; font-size: 15px; letter-spacing: -0.38px; border-top: 1px solid #121212;
            .th{height: 54px; font-family: 'NSKB'; line-height: 54px; border-bottom: 1px solid #e5e5e5; background-color: #f8f9fa;}
            .td{display: flex; align-items: center; justify-content: center; min-height: 75px; border-bottom: 1px solid #e5e5e5;
               &.small{padding: 5px; font-size: 12px; line-height: 1.57; letter-spacing: -0.35px; color: #727272;}
            }
         }
         .title{font-size: 18px; font-family: 'NSKB'; letter-spacing: -0.45px;}
         table{font-size: 15px; line-height: 1.67; letter-spacing: -0.38px; color: #121212;
            &.ta01{margin-top: 24px;}
            &.ta02{margin-top: 30px;}
            tr{vertical-align: text-top; text-align: left;
               th{padding-right: 8px;
                  &.blue{color: #4569ff;}
               }
               td{padding-top: 16px;
                  &.pt0{padding-top: 0;}
                  &.pt4{padding-top: 4px;}
                  &.pt8{padding-top: 8px;}
                  &.small{padding-top: 5px; font-size: 14px; line-height: 1.57; letter-spacing: -0.35px; color: #727272;}
                  &.bold{font-family: 'NSKR';font-weight:500;}
                  &.blue{color: #4569ff;}
                  &.red{color: #f25858;}
                  &.gray{color: #4a4a4a;}
                  &.tableTxt{padding-top: 0px;
                     .under{text-decoration: underline;}
                     em{padding: 0;}
                  }
                  .sBox{width: 100%; border-top: 1px solid #121212; font-size: 14px; letter-spacing: -0.35px;
                     .content{height: 40px; line-height: 40px; border-bottom: 1px solid #e5e5e5;
                        em{display: inline-block; width: 88px; height: 40px; margin-right: 20px; font-family: 'NSKB'; border-bottom: 1px solid #e5e5e5; text-align: center; background-color: #f8f9fa;}
                     }
                  }
               }
            }
            &.ta03{margin-top: 10px;border-top:1px solid #121212;
               th{border-bottom: 1px solid #e5e5e5;background-color: #f8f9fa;font-size:14px;padding:0 10px;min-width:100px;    vertical-align: middle;}
               td{font-size:14px;border-bottom: 1px solid #e5e5e5;vertical-align: middle;padding:10px;}
            }
         }
      }
   }
}

/**************************************************
   PopUp
**************************************************/
// CEO인사이트 팝업
// [개발검수1107] 다국어 수정 
.popWrap{position: relative; width: 100%; height: 100%; padding: 18px 16px 0 16px; box-sizing: border-box;
   // [개발검수0908] 팝업 텍스트 밀림 수정
   .popTxt01{font-size: 17px; font-family: 'NSKB'; letter-spacing: -0.55px; text-align: center;}
   .popTxt02{margin-top: 4px; font-size: 15px; letter-spacing: -0.38px; color: #8b8b8f; text-align: center;}
   
   .popBox01{width: 100%; padding: 20px 26px; border: 1px solid #e5e5e5; border-radius: 6px; background-color: #f9fafb; box-sizing: border-box;
      .btn_arrow{margin-top: 14px;}
   }
   .popBox02{width: 100%; margin-top: 12px; padding: 20px 17px; border: 1px solid #e5e5e5; border-radius: 6px; box-sizing: border-box;
      .tableBox{margin-top: 20px; align-items: baseline; padding-bottom: 8px; border-bottom: 1px solid #000000;
         .tableTit{font-size: 16px; letter-spacing: -0.4px; font-family: 'NSKB';}
         .tableTxt{font-size: 13px; letter-spacing: -0.33px; color: #121212;
            em{color: #607fff;}
         }
      }
      // [개발검수0927] 팝업 table 스타일 수정
      table{width: 100%; margin-top: 14px; table-layout: fixed;
         tr{height: 48px;}
         th{width: 70px; text-align: left; font-size: 15px; letter-spacing: -0.38px;
            em{color: #607fff;}
         }
         .inputBox{height: 38px;
            .inputText{height: 38px;}
         }
      }
      .inputError{margin-top: 10px; padding-left: 24px; font-size: 13px; letter-spacing: -0.33px; color: #fc5356; background: url('../../images/kor/sub/ceo/ic-input-error@2x.png') no-repeat left top 1px; background-size: 18px;}
   }
   
   .popBtnBox{justify-content: center; margin-top: 16px; padding-bottom: 24px;
      .popBtn{width: 118px; height: 48px; margin-right: 10px; border-radius: 50px; border: none; font-size: 15px; letter-spacing: -0.38px; color: #ffffff; box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
         &:last-child{margin-right: 0;}
         &.close{background-color: #acacac;}
         &.save{background-image: linear-gradient(72deg, #607fff 4%, #786eff 94%);}
      }
   }
}