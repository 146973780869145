@charset "UTF-8"; 

.mainIn{position: relative;min-width: 360px;width: 100%;overflow: hidden;background-color: #fafafa;
    .section01{position:sticky;top:0;width:100%;height:1053px;}
    .section02{position:sticky;top:0;width:100%;height:950px;}
    .section03{position:sticky;top:0;width:100%;height:2375px;}
    .section04{position:sticky;top:0;width:100%;height:1980px;}
    .section05{position:sticky;top:0;width:100%;height:710px;}
    .section06{position:sticky;top:0;width:100%;height:820px;}
    .section07{position:sticky;top:0;width:100%;height:720px;}
    .section08{position:sticky;top:0;width:100%;height:720px;}
    .section09{position:sticky;top:0;width:100%;height:660px;}
    .section10{position:sticky;top:0;width:100%;height:1250px;}
    .section11{position:sticky;top:0;width:100%;height:705px;background-color: #fafafa;}
    .section12{position:sticky;top:0;width:100%;height:2247px;}
}

// section01 ****************************************************************************************************
.section01{
    // 로고
    .textBox{position:absolute;top:251px;left:0;right:0;margin: 0 auto;z-index:1;text-align:center;
       .logo{width:100%;height:20px;margin:0 0 10px 0;background: url('../../images/kor/main/section01/logo@2x.png') no-repeat center;background-size:134px 20px;}
       .text{font-size:30px;font-family: 'NSKB';line-height:40px;letter-spacing:-0.75px;color:#000000;}
    }
    // 이미지들
    .item-01{position:absolute;top:99px;z-index:1;width:100%;height:118.5px;background: url('../../images/kor/main/section01/img-01-watch@2x.png') no-repeat;background-size:148px 118.5px;background-position-x:calc(50% - 120px);}
    .item-02{position:absolute;top:-115px;z-index:1;width:100%;height:483px;background: url('../../images/kor/main/section01/img-01-web@2x.png') no-repeat;background-size:544px 483px;background-position-x:calc(50% - -50px);}
    .item-03{position:absolute;top:239px;z-index:1;width:100%;height:461px;background: url('../../images/kor/main/section01/img-02-web@2x.png') no-repeat;background-size:516px 461px;background-position-x:calc(50% - 110px);}
    .item-04{position:absolute;top:402px;z-index:1;width:100%;height:170px;background: url('../../images/kor/main/section01/img-02-phone@2x.png') no-repeat;background-size:105px 170px;background-position-x:calc(50% - -106px);}
    .item-05{position:absolute;top:594px;z-index:1;width:100%;height:198px;background: url('../../images/kor/main/section01/img-03-tablet@2x.png') no-repeat;background-size:209px 198px;background-position-x:calc(50% - 123px);}
    .item-06{position:absolute;top:425px;z-index:1;width:100%;height:492px;background: url('../../images/kor/main/section01/img-03-web@2x.png') no-repeat;background-size:557px 492px;background-position-x:calc(50% - -105px);}
    .item-07{position:absolute;top:820px;z-index:1;width:100%;height:177px;background: url('../../images/kor/main/section01/img-04-phone@2x.png') no-repeat;background-size:112px 177px;background-position-x:calc(50% - 104px);}
    .item-08{position:absolute;top:869px;z-index:1;width:100%;height:118.8px;background: url('../../images/kor/main/section01/img-04-watch@2x.png') no-repeat;background-size:148px 118.8px;background-position-x:calc(50% - 19px);}
    .item-09{position:absolute;top:646px;z-index:1;width:100%;height:471px;background: url('../../images/kor/main/section01/img-04-tablet@2x.png') no-repeat;background-size:528px 471px;background-position-x:calc(50% - -152px);}
}

// section02 ****************************************************************************************************
.section02{
    .txt01{font-size:28px;font-family: 'NSKB';line-height:40px;color:#121212;letter-spacing:-0.7px;
        .color{color: transparent;-webkit-background-clip: text;background-image: linear-gradient(102deg, #00ebff -12%, #0aa5ff 42%, #8168ff 100%);}
    }
    .txt02{font-size:17px;font-family: 'NSKB';line-height:26px;color:#a2a2a2;letter-spacing:-0.42px;
        .txblack{color:#121212;}
    }
    .txt03{font-size:15px;font-family: 'NSKB';line-height:26px;color:#4d7cfe;letter-spacing:-0.38px;padding:0 20px 0 0;background:url('../../images/kor/ico/ico-arrow-right@2x.png') no-repeat right;background-size:13px;}

    .item-01{position:relative;top:60px;z-index:2;margin: 0 auto;text-align:center;}
    .item-02{position:relative;top:0;z-index:1;margin: 0 auto;width:360px;height:605px;background: url('../../images/kor/main/section02/img-combination@2x.png') no-repeat center;background-size:360px 605px;}
    .item-03{margin: 0 auto;text-align:center;}
    .item-04{margin: 16px auto 0px auto;text-align:center;width:fit-content;}
}

// section03 ****************************************************************************************************
.section03{
    .txt01{font-size:26px;font-family: 'NSKB';line-height:38px;color:#8b8b8f;letter-spacing:-0.65px;
        .txblack{color:#121212;}
    }
    .txt02{font-size:26px;font-family: 'NSKB';line-height:38px;color:#c7c7c7;letter-spacing:-0.65px;}
    .txt03{font-size:28px;font-family: 'NSKB';line-height:40px;color:#121212;letter-spacing:-0.7px;}
    .txt04{font-size:17px;font-family: 'NSKB';line-height:26px;color:#a2a2a2;letter-spacing:-0.42px;
        .txblack{color:#121212;}
    }

    .txDiv01{position:absolute;top:0px;left:0;right:0;height:38px;}
    .txDiv02{position:absolute;top:48px;left:0;right:0;height:38px;}
    .txDiv03{position:absolute;top:96px;left:0;right:0;height:38px;}

    .item-01{position: fixed;top: 50%;left: 0;right: 0;height:134px;text-align: center;transform: translateY(-50%);pointer-events: none;}
    .item-02{position:relative;top:1700px;z-index:1;width:360px;margin: 0 auto;padding:0 20px;text-align:left;pointer-events: none;}
    .item-03{position:relative;top:1718px;z-index:1;width:360px;margin: 0 auto;padding:0 20px;text-align:left;pointer-events: none;}
    .item-04{position:relative;top:1758px;z-index:1;margin: 0 auto;width:360px;height:312px;background: url('../../images/kor/main/section03/img-device-tablet@2x.png') no-repeat center;background-size:360px 312px;pointer-events: none;}
     
}
    
// section04 ****************************************************************************************************
.section04{
    .txt01{font-size:28px;font-family: 'NSKB';line-height:40px;color:#121212;letter-spacing:-0.7px;}
    .txt02{font-size:17px;font-family: 'NSKB';line-height:26px;color:#a2a2a2;letter-spacing:-0.42px;
        .txblack{color:#121212;}
    }

    .item-01{position:relative;top:80px;left:0;right:0;z-index:1;width:360px;margin: 0 auto;padding:0 20px;text-align:left;pointer-events: none;}
    .item-02{position:relative;top:98px;left:0;right:0;z-index:1;width:360px;margin: 0 auto;padding:0 20px;text-align:left;pointer-events: none;}
    .item-03{position:relative;top:98px;left:0;right:0;z-index:1;height:460px;pointer-events: none;
        .item-03-1{position:absolute;top:25px;z-index:1;width:100%;height:96px;background: url('../../images/kor/main/section04/card-1@2x.png') no-repeat;background-size:263px 96px;background-position-x:calc(50% + 51px);}
        .item-03-2{position:absolute;top:95px;z-index:1;width:100%;height:96px;background: url('../../images/kor/main/section04/card-2@2x.png') no-repeat;background-size:263px 96px;background-position-x:calc(50% - 92px);}
        .item-03-3{position:absolute;top:95px;z-index:1;width:100%;height:96px;background: url('../../images/kor/main/section04/card-3@2x.png') no-repeat;background-size:263px 96px;background-position-x:calc(50% + 146px);}
        .item-03-4{position:absolute;top:165px;z-index:1;width:100%;height:96px;background: url('../../images/kor/main/section04/card-4@2x.png') no-repeat;background-size:263px 96px;background-position-x:calc(50% - 48px);}
        .item-03-5{position:absolute;top:165px;z-index:1;width:100%;height:96px;background: url('../../images/kor/main/section04/card-5@2x.png') no-repeat;background-size:263px 96px;background-position-x:calc(50% + 190px);}
        .item-03-6{position:absolute;top:235px;z-index:1;width:100%;height:96px;background: url('../../images/kor/main/section04/card-6@2x.png') no-repeat;background-size:263px 96px;background-position-x:calc(50% + 31px);}
        .item-03-7{position:absolute;top:305px;z-index:1;width:100%;height:96px;background: url('../../images/kor/main/section04/card-7@2x.png') no-repeat;background-size:263px 96px;background-position-x:calc(50% + 130px);}
        .item-03-8{position:absolute;top:263px;z-index:1;width:100%;height:84px;background: url('../../images/kor/main/section04/ico-crm@2x.png') no-repeat;background-size:84px 84px;background-position-x:calc(50% - 137px);}
        .item-03-9{position:absolute;top:318px;z-index:1;width:100%;height:76px;background: url('../../images/kor/main/section04/ico-mail@2x.png') no-repeat;background-size:76px 76px;background-position-x:calc(50% - 30px);}
        .item-03-10{position:absolute;top:352px;z-index:1;width:100%;height:90px;background: url('../../images/kor/main/section04/ico-keep@2x.png') no-repeat;background-size:90px 90px;background-position-x:calc(50% - 100px);}
        
    }
    .item-04{position:relative;top:98px;left:0;right:0;z-index:1;margin: 0 auto;height:610px;background: url('../../images/kor/main/section04/img-onestop-2@2x.png') no-repeat center;background-size:620px 610px;pointer-events: none;}
}

// section05 ****************************************************************************************************
.section05{
    .txt01{font-size:28px;font-family: 'NSKB';line-height:40px;color:#121212;letter-spacing:-0.7px;}
    .txt02{font-size:17px;font-family: 'NSKB';line-height:26px;color:#a2a2a2;letter-spacing:-0.42px;
        .txblack{color:#121212;}
    }
    .txt03{width:fit-content;font-size:15px;font-family: 'NSKB';line-height:26px;color:#4d7cfe;letter-spacing:-0.38px;padding:0 20px 0 0;background:url('../../images/kor/ico/ico-arrow-right@2x.png') no-repeat right;background-size:13px;}

    .item-01{position:relative;top:40px;left:0;right:0;z-index:1;width:360px;margin: 0 auto;padding:0 20px;text-align:left;}
    .item-02{position:relative;top:10px;left:0;right:0;z-index:1;margin: 0 auto;height:343px;background: url('../../images/kor/main/section05/img-time@2x.png') no-repeat center;background-size:400px 343px;}
    .item-03{position:relative;top:30px;left:0;right:0;z-index:1;width:360px;margin: 0 auto;padding:0 20px;text-align:left;}
    .item-04{position:relative;top:46px;left:0;right:0;z-index:1;width:360px;margin: 0 auto;padding:0 20px;text-align:left;}
}

// section06 ****************************************************************************************************
.section06{
    .txt01{font-size:28px;font-family: 'NSKB';line-height:40px;color:#121212;letter-spacing:-0.7px;}
    .txt02{font-size:17px;font-family: 'NSKB';line-height:26px;color:#a2a2a2;letter-spacing:-0.42px;
        .txblack{color:#121212;}
    }

    .item-01{position:relative;top:60px;left:0;right:0;z-index:2;width:360px;margin: 0 auto;padding:0 20px;text-align:center;}
    .item-02{position:relative;top:78px;left:0;right:0;z-index:2;width:360px;margin: 0 auto;padding:0 20px;text-align:center;}
    .item-03{position:relative;top:-120px;left:0;right:0;z-index:1;margin: 0 auto;height:704px;background: url('../../images/kor/main/section06/img-rapid@2x.png') no-repeat center;background-size:1200px 704px;pointer-events: none;}
}

// section07 ****************************************************************************************************
.section07{
    .txt01{font-size:28px;font-family: 'NSKB';line-height:40px;color:#121212;letter-spacing:-0.7px;}
    .txt02{font-size:17px;font-family: 'NSKB';line-height:26px;color:#a2a2a2;letter-spacing:-0.42px;
        .txblack{color:#121212;}
    }

    .item-01{position:relative;top:60px;left:0;right:0;z-index:2;width:360px;margin: 0 auto;padding:0 20px;text-align:left;}
    .item-02{position:relative;top:78px;left:0;right:0;z-index:2;width:360px;margin: 0 auto;padding:0 20px;text-align:left;}
    .item-03{position:relative;top:110px;left:0;right:0;z-index:1;margin: 0 auto;height:422px;background: url('../../images/kor/main/section07/img-device-web-2@2x.png') no-repeat center;background-size:564px 422px;pointer-events: none;}
}

// section08 ****************************************************************************************************
.section08{
    .txt01{font-size:28px;font-family: 'NSKB';line-height:40px;color:#121212;letter-spacing:-0.7px;}
    .txt02{font-size:17px;font-family: 'NSKB';line-height:26px;color:#a2a2a2;letter-spacing:-0.42px;
        .txblack{color:#121212;}
    }

    .item-01{position:relative;top:60px;left:0;right:0;z-index:2;width:360px;margin: 0 auto;padding:0 19px;text-align:left;}
    .item-02{position:relative;top:78px;left:0;right:0;z-index:2;width:360px;margin: 0 auto;padding:0 19px;text-align:left;}
    .item-03{position:relative;top:80px;left:0;right:0;z-index:1;margin: 0 auto;
        .slideBox{height:450px;
            .section08Slide{position: absolute; top: 0; left: 50%; transform: translateX(-50%); width: 100%; height: 100%;
                .slick-slide{transition: 0.5s; opacity: 0.3; width: 220px; height: 100%;
                    img{width: 309px;margin: 0 auto;transform: translateX(-10px);}
                    &.slick-active{opacity: 1;}
                }
                .textBox{position: relative;top: -64px;font-size: 15px;line-height: 20px;font-family: 'NSKB';color: #121212;letter-spacing: -0.38px;text-align: center;
                    .txBlue{font-family: 'NSKR';color:#4d7cfe;}
                }
            }
        }
    }
}

// section09 ****************************************************************************************************
.section09{
    .txt01{font-size:28px;font-family: 'NSKB';line-height:40px;color:#121212;letter-spacing:-0.7px;}
    .txt02{font-size:17px;font-family: 'NSKB';line-height:26px;color:#a2a2a2;letter-spacing:-0.42px;
        .txblack{color:#121212;}
    }

    .item-01{position:relative;top:50px;left:0;right:0;z-index:2;width:360px;margin: 0 auto;padding:0 20px;text-align:left;}
    .item-02{position:relative;top:68px;left:0;right:0;z-index:2;width:360px;margin: 0 auto;padding:0 20px;text-align:left;}
    .item-03{position:relative;top:50px;left:0;right:0;z-index:1;margin: 0 auto;
        .slideBox{height:370px;
            .section09Slide{position: absolute; top: 0; left: 50%; transform: translateX(-50%); width: 100%; height: 100%;
                .slick-slide{transition: 0.5s; opacity: 0.3; width: 360px; height: 100%;
                    img{width: 542px;transform:translateX(-90px);}
                    &.slick-active{opacity: 1;}
                }
                .textBox{position: relative;top: -384px;font-size: 14px;line-height: 32px;color: #607fff;letter-spacing: -0.35px;text-align: center;width: fit-content;margin: 0 auto;padding: 0 14px;border-radius: 32px;background-color: rgba(96, 127, 255, 0.1);}
            }
        }
    }
}

// section10 ****************************************************************************************************
.section10{
    .txt01{font-size:20px;font-family: 'NSKB';line-height:30px;color:#a2a2a2;letter-spacing:-0.5px;
        .txblack{color:#121212;}
    }

    .item-01{position:relative;top:154px;left:0;right:0;z-index:2;width:360px;margin: 0 auto;padding:0 20px;text-align:center;}
    .item-02{position:relative;top:-90px;left:0;right:0;z-index:1;margin: 0 auto;height:647px;background: url('../../images/kor/main/section10/img-agile@2x.png') no-repeat center;background-size:650px 647px;pointer-events: none;}
}

// section11 ****************************************************************************************************
.section11{
    .txt01{font-size:28px;font-family: 'NSKB';line-height:40px;color:#121212;letter-spacing:-0.7px;}
    .txt02{font-size:17px;font-family: 'NSKB';line-height:26px;color:#a2a2a2;letter-spacing:-0.42px;
        .txblack{color:#121212;}
    }

    .item-01{position:relative;top:60px;left:0;right:0;z-index:2;width:360px;margin: 0 auto;padding:0 28px;text-align:left;}
    .item-02{position:relative;top:78px;left:0;right:0;z-index:2;width:360px;margin: 0 auto;padding:0 28px;text-align:left;}
    .item-03{position:relative;top:138px;left:0;right:0;z-index:1;margin: 0 auto;
        .slideBox{height:220px;
            .section11Slide{position: absolute; top: 0; left: 50%; transform: translateX(-50%); width: 100%; height: 100%;
                .slick-slide{transition: 0.5s; opacity: 0.3; width: 320px; height: 170px;
                    &.slick-active{opacity: 1;}
                    
                    .video01{position:relative;min-width:302px;height:170px;background:url('../../images/kor/main/section11/img-video-01@2x.png') no-repeat center;background-size:304px 170px;}
                    .video02{position:relative;min-width:302px;height:170px;background:url('../../images/kor/main/section11/img-video-02@2x.png') no-repeat center;background-size:304px 170px;}
                    .video03{position:relative;min-width:302px;height:170px;background:url('../../images/kor/main/section11/img-video-03@2x.png') no-repeat center;background-size:304px 170px;}
                    .video04{position:relative;min-width:302px;height:170px;background:url('../../images/kor/main/section11/img-video-04@2x.png') no-repeat center;background-size:304px 170px;}
        
                    .logo{position:absolute;top:16px;left:28px;width:82.8px;height:11px;background:url('../../images/kor/main/section11/logo@2x.png') no-repeat center;background-size:82.8px 11px;}
                    .play{position:absolute;bottom:0;right:15px;width:58px;height:58px;background:url('../../images/kor/main/section11/btn-play-s@2x.png') no-repeat center;background-size:58px;}
                    .text{position:absolute;bottom:14px;left:28px;
                        .txt001{font-size:13px;color:#ffffff;line-height:20px;letter-spacing:-0.32px;}
                        .txt002{font-size:16px;font-family: 'NSKB';color:#ffffff;line-height:24px;letter-spacing:-0.4px;}
                    }
                    .videoBox{width: 302px;height: 170px;margin:0 auto;border-radius: 5px;overflow: hidden;}
                }
            }
        }
    }
}

// section12 ****************************************************************************************************
.section12{
    .txt01{font-size:26px;font-family: 'NSKB';line-height:38px;color:#8b8b8f;letter-spacing:-0.65px;
        .txblack{color:#121212;}
    }

    .txDiv01{position:absolute;top:0px;left:0;right:0;height:38px;}
    .txDiv02{position:absolute;top:48px;left:0;right:0;height:38px;}
    .txDiv03{position:absolute;top:96px;left:0;right:0;height:38px;}

    .item-01{position: fixed;top: 50%;left: 0;right: 0;height:134px;text-align: center;transform: translateY(-50%);pointer-events: none;}
    .item-02{position:relative;top:1100px;left:0;right:0;z-index:1;height:564px;pointer-events: none;
        .item-02-1{position:absolute;top:8px;z-index:1;width:100%;height:126px;background: url('../../images/kor/main/section12/img-05-phone@2x.png') no-repeat;background-size:204px 126px;background-position-x:calc(50% - 47px);}
        .item-02-2{position:absolute;top:0px;z-index:1;width:100%;height:118.5px;background: url('../../images/kor/main/section12/img-05-watch@2x.png') no-repeat;background-size:148px 118.5px;background-position-x:calc(50% + 94px);}
        .item-02-3{position:absolute;top:131px;z-index:1;width:100%;height:198px;background: url('../../images/kor/main/section12/img-06-tablet@2x.png') no-repeat;background-size:209px 198px;background-position-x:calc(50% - 130px);}
        .item-02-4{position:absolute;top:-12px;z-index:1;width:100%;height:483px;background: url('../../images/kor/main/section12/img-06-web@2x.png') no-repeat;background-size:544px 483px;background-position-x:calc(50% + 79px);}
        .item-02-5{position:absolute;top:177px;z-index:1;width:100%;height:458px;background: url('../../images/kor/main/section12/img-07-tablet@2x.png') no-repeat;background-size:511px 458px;background-position-x:calc(50% - 86px);}
        .item-02-6{position:absolute;top:338px;z-index:1;width:100%;height:186px;background: url('../../images/kor/main/section12/img-07-phone@2x.png') no-repeat;background-size:115px 186px;background-position-x:calc(50% + 125px);}
    }
    .item-03{position:relative;top:1140px;left:0;right:0;margin: 0 auto;z-index:1;text-align:center;
        .logo{width:100%;height:20px;margin:0 0 14px 0;background: url('../../images/kor/main/section12/banner-logo@2x.png') no-repeat center;background-size:138px 20px;}
        .text{font-size:28px;line-height:40px;font-family: 'NSKB';color:#121212;letter-spacing:-0.7px;}
        .btn{width: 206px;height: 48px;margin: 31px auto;border-radius: 100px;box-shadow: 0 3px 10px 0 rgba(123, 123, 123, 0.2);background-image: linear-gradient(to right, #0af 0%, #8168ff 100%);text-align:center;
            .txt{padding:0 16px 0 0;font-size:15px;line-height:48px;color:#ffffff;letter-spacing:-0.38px;text-align:center;box-sizing: border-box;
               background:url('../../images/kor/main/section12/ico-arrow-right-w@2x.png') no-repeat right;background-size:14px;
            }
        }
    }
    .item-04{position:absolute;bottom:0px;left:0;right:0;height:240px;margin: 0 auto;z-index:1;text-align:center;background: url('../../images/kor/main/section12/img-banner@2x.png') no-repeat center;background-size:100% 240px;
        .logo{width:100%;height:26px;margin:48px 0 8px 0;background: url('../../images/kor/main/section12/banner-logo-w@2x.png') no-repeat center;background-size:164px 26px;}
        .text{font-size:25px;line-height:37px;color:#ffffff;letter-spacing:-0.62px;}
        .btn{width: 206px;height: 48px;margin: 31px auto;border-radius: 100px;box-shadow: 0 3px 6px 0 rgba(123, 123, 123, 0.16);border:1px solid #ffffff;text-align:center;
            .txt{padding:0 16px 0 0;font-size:15px;line-height:48px;color:#ffffff;letter-spacing:-0.38px;text-align:center;box-sizing: border-box;
               background:url('../../images/kor/main/section12/ico-arrow-right-w@2x.png') no-repeat right;background-size:14px;
            }
        }
    }
}
