@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 56px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}


/* Arrows */
// PR센터
.modalBox{
    .slick-prev{position: absolute; top: 105%; left: -10px; z-index: 1; width: 40px;height: 40px;background: url("../images/kor/sub/media/btn-previous@2x.png") no-repeat center center;background-size: 40px 40px;border: none;outline: none;font-size: 0;}
    .slick-next{position: absolute; top: 105%; right: -10px; z-index: 1; width: 40px;height: 40px;background: url("../images/kor/sub/media/btn-next@2x.png") no-repeat center center;background-size: 40px 40px;border: none;outline: none;font-size: 0;}
}

.modalPrintBox{
    .slick-prev{position: absolute; top: 100%; left: -10px; z-index: 1; width: 64px;height: 64px;background: url('../images/kor/sub/media/btn-previous@2x.png') no-repeat center;background-size: 40px 40px;border: none;outline: none;font-size: 0;}
    .slick-next{position: absolute; top: 100%; right: -10px; z-index: 1; width: 64px;height: 64px;background: url('../images/kor/sub/media/btn-next@2x.png') no-repeat center;background-size: 40px 40px;border: none;outline: none;font-size: 0;}
}

/* Dots */
// 공통
.amaDots{
    .slick-dots{position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); list-style: none;
        ul{width: 222px; margin: 0 auto;justify-content:center;
            li{width: 49px; height: 40px;margin: 0 3px; padding: 0;font-size: 0px;
                &.slick-active > div::after{content:'';position:absolute;top:19px;width:100%;height:2px;background-color: #607fff; opacity: 1; transition: 0.7s;}
                & > div{position:relative;width:100%;height:40px;}
                & > div::after{content:'';position:absolute;top:19px;width:100%;height:2px;background-color: #000000; opacity: 0.1; transition: 0.7s;}
            }
        }
    }
}

// PR센터
.modalBox{
    .slick-dots{position: absolute; bottom: -40px; left: 50%; transform: translateX(-50%);
        li{display: inline-block;
            button{ color: #e2e2e2; font-size: 14px; letter-spacing: -0.35px; border: none; outline: none; background-color: transparent;}
            &.slick-active{
                button{display: block;}
            }
        }
    }
}