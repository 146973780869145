/* Slider */

.slick-slider {position: relative; display: block; box-sizing: border-box;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;-ms-touch-action: pan-y;touch-action: pan-y;-webkit-tap-highlight-color: transparent;}
.slick-list {position: relative; overflow: hidden; display: block;margin: 0;padding: 0;
    &:focus {outline: none;}
    &.dragging {cursor: pointer;cursor: hand;}
}

.slick-slider .slick-track,
.slick-slider .slick-list {-webkit-transform: translate3d(0, 0, 0);-moz-transform: translate3d(0, 0, 0);-ms-transform: translate3d(0, 0, 0);-o-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
.slick-track {position: relative;left: 0;top: 0;display: block;margin-left: auto;margin-right: auto;
    &:before, &:after {content: "";display: table;}
    &:after {clear: both;}
    .slick-loading & {visibility: hidden;}
}
.slick-slide {float: left;height: 100%;min-height: 1px;
    [dir="rtl"] & {float: right;}
    img {display: block;}
    &.slick-loading img {display: none;}
    display: none;
    &.dragging img {pointer-events: none;}
    .slick-initialized & {display: block;}
    .slick-loading & {visibility: hidden;}
    .slick-vertical & {display: block;height: auto;border: 1px solid transparent;}
}
.slick-arrow.slick-hidden {display: none;}

// 여기서부터 커스텀
// 가상체험관
.virtualSlide{position: absolute; left: 50%; transform: translateX(-50%); width: 1264px; height: 100%; padding-top: 20px;
    img{width: 304px; height: 190px;}
    .slick-list{width: 100%; height: 100%; padding-top: 20px !important;}
    .slick-track{height: 100%;}
    .slick-slide{transition: 0.5s; opacity: 0.5; width: 320px !important; height: 358px;
        > div{width: 304px !important; height: 100%; margin: 0 auto;}
        > div > div {position: relative; height: 358px; border-radius: 10px; box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);}
        &.slick-active{opacity: 1;}
    }
    // [개발검수1107] 다국어 수정 
    .box{width: 304px; height: 180px; padding: 18px 24px 30px 24px; border-radius: 0 0 10px 10px; background: rgba(10, 11, 15, 0.9); box-sizing: border-box;
        .text01{position: relative; padding-bottom: 14px; font-size: 17px; font-family:'NSKB'; letter-spacing: -0.43px; color: #ffffff;
            &::after{content: ''; display: inline-block; width: 100%; height: 1px; opacity: 0.2; background-color: #dfe5f4;}
        }
        .text02{font-size: 15px; line-height: 1.53; letter-spacing: -0.38px; color: rgba(255, 255, 255, 0.9)}
        .text04{margin-left: 8px; margin-bottom: 5px; font-size: 13px; line-height: 1.77; letter-spacing: -0.33px; color: rgba(255, 255, 255, 0.6);}
    }
    .text03{position: absolute; top: 5px; left: 5px; font-size: 14px; font-family:'NSKB'; letter-spacing: -0.35px; color: #ffffff; box-sizing: border-box;
        &.tag1{width: 177px; height: 64px; padding:18px 0 21px 31px; background: url('../images/kor/sub/virtualEx/tag-1@2x.png') no-repeat center center; background-size: 100%;}
        &.tag2{width: 160px; height: 64px; padding:18px 0 21px 31px; background: url('../images/kor/sub/virtualEx/tag-2@2x.png') no-repeat center center; background-size: 100%;}
        &.tag3{width: 201px; height: 64px; padding:18px 0 21px 31px; background: url('../images/kor/sub/virtualEx/tag-3@2x.png') no-repeat center center; background-size: 100%;}
        &.tag4{width: 158px; height: 64px; padding:18px 0 21px 31px; background: url('../images/kor/sub/virtualEx/tag-4@2x.png') no-repeat center center; background-size: 100%;}
    }
}

// 미디어센터 TVCF
.modalBox{position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 320px; height: 178px;
    .slick-list{height: 178px; border-radius: 6px; overflow: hidden;}
    .slick-track{height: 100%;}
    .videoPopBox{position: relative; width: 320px; height: 178px; border-radius: 6px; overflow: hidden;
        .imgBox{position: relative; overflow: hidden;
            // [개발검수1103] 이미지 둥근 모서리
            img{width: 320px; height: 178px; border-radius: 6px;}
            .play{position: absolute; top: 60px; left: 134px; width: 70px; height: 70px; background: url('../images/kor/sub/media/btn-play@2x.png');background-size: 70px 70px;}    
        }
        .videoBox{position: relative; height: 178px; border-radius: 6px; overflow: hidden;
            > div{z-index: 99; position: absolute; top: 0; left: 0; overflow: hidden;}
            video{object-fit: fill; object-position: center;
                &:not(:root):fullscreen {object-fit:contain;}
            }
        }
    } 
}

.modalPrintBox{position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 319px; height: 441px;
    .slick-list{height: 441px; border-radius: 6px; overflow: hidden;}
    .printPopBox{position: relative; width: 319px; height: 441px; border-radius: 6px; overflow: hidden;
       img{width: 319px; height: 441px; object-fit: fill; object-position: center;}
        &.modalPrintSize{width: 320px; height: 550px;
            img{width: 320px;}
        }
    }
}

// 브랜드 새롭게 정의하다
.brandSlide{position: absolute; top: 0; left: 50%; transform: translateX(-50%); width: 1680px; height: 100%;
    .slick-slide{transition: 0.5s; opacity: 0.3; width: 340px !important; height: 100%;
        //&:last-of-type{width: 320px !important;}
        img{width: 320px; height: 298px; margin: 0 auto;}
        &.slick-active{opacity: 1;}
    }
}